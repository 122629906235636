type SectionLabelProps ={
    title:string
    center?:boolean
}
const SectionCenterLabel=(props:SectionLabelProps)=>{
    return (
        <div className="flex flex-row justify-start items-center">
            {props.center && (<span className="flex-1 bg-grey1 h-0.25"></span>)} 
            <h3 
            className="text-xl font-bold text-blue py-3">{props.title}</h3>
          <span className="flex-1 bg-grey1 h-0.25"></span>
        </div> 
    )
}
export default SectionCenterLabel
