import { useLocation } from 'react-router-dom'
import Summary from 'containers/ExportPdf/ActivationProgramsPdf/Summary';

const ActivationProgramsPdf = () => {
    const location = useLocation();
    const decode_and_parse: any = (key: string, defaultValue: string) => {
        const value = new URLSearchParams(location.search).get(key) || defaultValue;
        return JSON.parse(decodeURIComponent(value))
    }
    const webinar_summary = decode_and_parse("webinar_summary", "{}");

    return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <main id="myiFrame">
            <div className="CardVisualization py-6 bg-grey">
                <div className="mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-bold text-blue mb-4">Activation Programs</h1>
                </div>
                <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                    <div className="flex flex-wrap justify-between space-x-8  ">
                        <Summary webinar_summary={webinar_summary} className={'bg-white shadow sm:rounded-lg flex-1'} title={'Summary'} />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ActivationProgramsPdf