
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'components/Chart'
import SectionCenterLabel from 'components/SectionCenter'
import {
  REACT_APP_METABASE_CLINICAL_INSIGHTS,
  REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH,
  REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG,
  REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE
} from 'constants/megabase'
import { applyDate, applyYear, applyTypeFilterDatetime, selectAuth } from "containers/SingIn/authSlice";
import moment from 'moment';
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { refreshProgramAction } from "containers/SingIn/authAPI";
import { add_params_and_push, ten_years_ago } from "utils";
import FilterValue from "containers/Layout/FilterBar/filter_value";

type NumberType = {
  name: string,
  depression: string,
  anxiety: string,
  stress: string
}

const people: NumberType[] = [
  { name: 'Normal', depression: '0-9', anxiety: '0-7', stress: '0-14' },
  { name: 'Mild', depression: '10-13', anxiety: '8-9', stress: '15-18' },
  { name: 'Moderate', depression: '14-20', anxiety: '10-14', stress: '19-25' },
  { name: 'Severe', depression: '21-27', anxiety: '15-19', stress: '26-33' },
  { name: 'Extremely Severe', depression: '28', anxiety: '20', stress: '34' },
  // More people...
]


const ClinicalInsights = () => {
  const history = useHistory()

  const { department } = useSelector(selectAuth);
  const question_monthly_dass = department ? REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE : REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(applyTypeFilterDatetime(0));
    add_params_and_push({ typeFilterDatetime: 0 }, location, queryString, history)
    const userData = { month: moment().format("MM/YYYY") };
    dispatch(applyDate(userData));
    dispatch(applyYear({ year: moment().year() }));

    setTimeout(() => {
      dispatch(refreshProgramAction(location.search));

    }, 0);

    return (() => {
      // add_params_and_push({typeFilterDatetime: 0})
      dispatch(applyTypeFilterDatetime(0));
    })
  }, []);

  return (
    <main>
      <div className="py-6 bg-grey">
      <FilterValue />
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-blue mb-4">Clinical Insights</h1>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10 items-center justify-center">
          <span className="font-normal text-xl text-blue" >{'The Depression, Anxiety and Stress Scale (DASS-21) is a set of three self-report scales designed to measure the emotional states of depression, anxiety and stress.'}</span>
        </div>
        <div className="py-4">
          <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <div className="flex flex-wrap justify-between space-x-8  ">
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH} />
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_CLINICAL_INSIGHTS} />
            </div>

            <div className="flex flex-wrap justify-between space-x-8 pt-8 ">
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={question_monthly_dass} />
            </div>
          </div>
          <div className="flex flex-col mx-auto px-4 sm:px-6 md:px-8">
            <SectionCenterLabel title={"Interpretation of DASS Scores"} />
          </div>

          <div className="flex flex-wrap justify-between space-x-8 mx-8 bg-white shadow sm:rounded-lg flex-1 ">
            <div className="w-full mx-auto">
              <div className="flex flex-wrap justify-between space-x-8  ">
                <div className="w-full flex flex-col">
                  <div className="w-full -my-2 overflow-x-auto">
                    <div className="w-full py-2 align-middle inline-block ">
                      <div className="w-full shadow overflow-hidden">
                        <table className="w-full divide-y divide-grey1">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Meaning
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Depression
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Anxiety
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Stress
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {people.map((person: NumberType, personIdx) => (
                              <tr key={person.name} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.depression}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.anxiety}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.stress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ClinicalInsights