
import Chart from 'components/Chart'
import {
  REACT_APP_METABASE_USER_ACQUISITION,
  REACT_APP_METABASE_TOP_CONCERNS_BREAKDOWN,
  REACT_APP_METABASE_LOCATION_BREAKDOWN,
  REACT_APP_METABASE_GENDER_BREAKDOWN,
  REACT_APP_METABASE_AGE_BREAKDOWN
} from 'constants/megabase'
import FilterValue from 'containers/Layout/FilterBar/filter_value'
import { refreshProgramAction } from 'containers/SingIn/authAPI'
import { selectAuth } from 'containers/SingIn/authSlice'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { add_params_and_push } from 'utils'
import ListProgramsTableUserDemo from './ListPrograms'


const UserDemographics = () => {
  const { webinars } = useSelector(selectAuth)
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    dispatch(refreshProgramAction(location.search));
    add_params_and_push({ typeFilterDatetime: 0 }, location, queryString, history)

  }, []);

  return (
    <main>
      <div className="py-6 bg-grey">
        <FilterValue />
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-blue mb-4">User Demographics</h1>
        </div>
        <div className="py-4">
          <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <div className="justify-between">
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_USER_ACQUISITION} />
              {/* <ListProgramsTableUserDemo data={webinars} /> */}
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_LOCATION_BREAKDOWN} />
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_TOP_CONCERNS_BREAKDOWN} />
            </div>

          </div>
          {/* <div className="flex flex-wrap justify-between space-x-8 bg-white shadow sm:rounded-lg flex-1 ">
          </div> */}
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            <div className="justify-between">
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_GENDER_BREAKDOWN} />
              <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_AGE_BREAKDOWN} />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default UserDemographics