import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState, AppThunk } from "store/store";
import { AuthState } from "./types";
const initialState: AuthState = {
  initURL: "",
  useremail: "",
  messageOTP: "",
  month: moment().format("MM/YYYY"),
  year: moment().year(),
  messageSendEmail: "",
  programId: "latest",
  programs: [],
  department: "",
  is_dependent: false,
  is_referral_user: false,
  market: "",
  typeFilterDatetime: 0,
  total_user_do_dass_this_month: 0,
  total_user_of_org_do_dass_last_month: 0,
  total_user_of_org_do_dass_this_month: 0,
  video_therapy_session_count: 0,
  total_user_of_org_do_dass: 0,
  total_user_of_org_do_other_assessment: 0,
  is_fwd_org: false,
  is_aia_org: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearMessEmail: (state) => {
      state.messageSendEmail = "";
    },
    saveMessEmail: (state, action) => {
      state.messageSendEmail = action.payload;
    },
    saveEmail: (state, action) => {
      state.useremail = action.payload;
    },
    saveMessOTP: (state, action) => {
      state.messageOTP = action.payload;
    },
    clearMessOTP: (state) => {
      state.messageOTP = "";
    },
    signoutUserSuccess: (state) => {
      state.useremail = "";
      state.initURL = "";
    },
    loadUserDataSuccess: (state, action) => {
      const {
        app,
        org,
        program,
        top_learn,
        user,
        webinar_summary,
        is_fwd_org,
        is_aia_org,
        webinars,
        recommendations,
        org_department_users,
        org_market_users,
        countries,
        departmental_health_overview,
        total_user_do_dass_this_month,
        total_user_of_org_do_dass_last_month,
        total_user_of_org_do_dass_this_month,
        video_therapy_session_count,
        tf_aia_cs,
        total_user_of_org_do_dass,
        total_user_of_org_do_other_assessment
      } = action.payload;
      state.app = app;
      state.org = org;
      state.program = program;
      state.top_learn = top_learn;
      state.webinar_summary = webinar_summary;
      state.is_fwd_org = is_fwd_org;
      state.is_aia_org = is_aia_org;
      state.webinars = webinars;
      state.user = user;
      state.recommendations = recommendations;
      state.org_department_users = org_department_users;
      state.org_market_users = org_market_users;
      state.departmental_health_overview = departmental_health_overview;
      state.countries = countries;
      state.total_user_do_dass_this_month = total_user_do_dass_this_month;
      state.total_user_of_org_do_dass_last_month =
        total_user_of_org_do_dass_last_month;
      state.total_user_of_org_do_dass_this_month =
        total_user_of_org_do_dass_this_month;
      state.video_therapy_session_count =
        video_therapy_session_count;
      state.total_user_of_org_do_dass =
        total_user_of_org_do_dass;
      state.total_user_of_org_do_other_assessment =
        total_user_of_org_do_other_assessment;
      state.tf_aia_cs =
      tf_aia_cs;
    },
    userSignOut: (state) => {
      state.useremail = "";
      state.initURL = "";
    },
    loadProgramSuccess: (state, action) => {
      state.programs = action.payload;
    },
    changeProgram: (state, action) => {
      state.programId = action.payload;
    },
    changeDepartment: (state, action) => {
      state.department = action.payload;
    },
    changeMarket: (state, action) => {
      state.market = action.payload;
    },
    changeIsDependent: (state, action) => {
      state.is_dependent = action.payload;
    },
    changeIsReferralUser: (state, action) => {
      state.is_referral_user = action.payload;
    },
    applyDate: (state, action) => {
      state.month = action.payload.month;
    },
    applyYear: (state, action) => {
      state.year = action.payload.year;
    },
    applyTypeFilterDatetime: (state, action) => {
      state.typeFilterDatetime = action.payload;
    },
  },
});

export const userSignOutAction = (): AppThunk => (dispatch, getState) => {
  localStorage.removeItem("token");
  dispatch(userSignOut());
};
export const {
  clearMessEmail,
  saveEmail,
  saveMessEmail,
  // saveToken,
  clearMessOTP,
  saveMessOTP,
  signoutUserSuccess,
  loadUserDataSuccess,
  userSignOut,
  loadProgramSuccess,
  changeProgram,
  applyDate,
  applyYear,
  changeDepartment,
  changeIsDependent,
  changeMarket,
  applyTypeFilterDatetime,
  changeIsReferralUser
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
