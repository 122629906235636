

import BoxTitle from "components/BoxTitle";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const PieChartViewPdf = (props: any) => {
    const app = props.app;
    return (
        <div className={props.className}>
            <div className="">
                <BoxTitle title={props.title} />
                <div className="flex flex-row justify-center space-x-20 pb-10 ">
                    <div className="w-56 flex flex-col items-center">
                        <span className="pb-5 text-2xl font-normal text-blue" >Learn</span>
                        <CircularProgressbar value={app?.engagement_learn ?? 0} text={`${app?.engagement_learn ?? 0}%`} />
                        <div className="text-center  text-gray500 mt-6 leading-6 font-normal">
                        of the active users enrolled and engaged with learn packs, lessons, breathing timers and audio guides
                        </div>
                    </div>
                    <div className="w-56 flex flex-col items-center">
                        <span className="pb-5 text-2xl font-normal text-blue" >Chat</span>
                        <CircularProgressbar value={app?.engagement_chat ?? 0} text={`${app?.engagement_chat ?? 0}%`} />
                        <div className="text-center text-gray500 mt-6 leading-6	font-normal">
                            of the active coachees that engaged with their mental health professional
                        </div>
                    </div>
                    <div className="w-56 flex flex-col items-center">
                        <span className="pb-5 text-2xl font-normal text-blue" >Grow</span>
                        <CircularProgressbar value={app?.engagement_grow ?? 0} text={`${app?.engagement_grow ?? 0}%`} />
                        <div className="text-center text-gray500 mt-6 leading-6	font-normal">
                            of the active users that logged their mood, thought journals and emotional health assessments
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PieChartViewPdf
