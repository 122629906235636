import axios from 'axios';
import { getToken } from 'utils';
export const BASE_URL=process.env.REACT_APP_API_URL;
// export const BASE_URL="http://localhost:4000";

const  instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    //"Access-Control-Allow-Origin": "*",
  }
});

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  if(getToken() && config.headers){
    config.headers["x-access-token"] = getToken();
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default instance;



