import images from 'assets/images'
import { refreshProgramAction } from 'containers/SingIn/authAPI';
import { changeDepartment, changeIsReferralUser, changeMarket } from 'containers/SingIn/authSlice';
import moment from 'moment';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { add_params_and_push } from 'utils';

const FilterValue = (props: any) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()

    const is_dependent = new URLSearchParams(location.search).get('is_dependent') == "true"
    const isReferralUser = new URLSearchParams(location.search).get('isReferralUser') == "true"
    const startDate = new URLSearchParams(location.search).get('startDate')
    const endDate = new URLSearchParams(location.search).get('endDate')
    const department = new URLSearchParams(location.search).get('department') || ""
    const market = new URLSearchParams(location.search).get('market') || ""
    const is_apply_filter = new URLSearchParams(location.search).get('is_apply_filter') == "true";

    const clearIsDependen = () => {
        add_params_and_push({ is_dependent: false, is_apply_filter: !is_apply_filter }, location, queryString, history)
        dispatch(changeDepartment(false));
        dispatch(refreshProgramAction(location.search));
    }
    const clearIsReferralUser = () => {
        add_params_and_push({ isReferralUser: false, is_apply_filter: !is_apply_filter, partner_id: "" }, location, queryString, history)
        dispatch(changeIsReferralUser(false));
        dispatch(refreshProgramAction(location.search));
    }
    const clearDate = () => {
        add_params_and_push({ startDate: null, endDate: null, is_clear_date: true, is_apply_filter: !is_apply_filter  }, location, queryString, history)
        dispatch(refreshProgramAction(location.search));
    }
    const clearDepartment = () => {
        add_params_and_push({ department: null, is_apply_filter: !is_apply_filter  }, location, queryString, history)
        dispatch(changeDepartment(""));
        dispatch(refreshProgramAction(location.search));
    }
    const clearMarket = () => {
        add_params_and_push({  market: null, is_apply_filter: !is_apply_filter  }, location, queryString, history)
        dispatch(changeMarket(""));

        dispatch(refreshProgramAction(location.search));
    }

    return (
        <div className='flex  mx-auto px-1 sm:px-6 md:px-8 mb-4'>
            {is_dependent && (<div className='mr-2 rounded-full py-1 px-2 flex items-center' style={{ color: "#59817D", backgroundColor: "#CBE3E1" }}>
                <div>Is Dependent</div>
                <div 
                onClick={clearIsDependen}
                className='ml-2 mr-2 text-xs cursor-pointer'>x</div>
            </div>)}
            {isReferralUser && (
                <div className='mr-2 rounded-full py-1 px-2 flex items-center' style={{ color: "#59817D", backgroundColor: "#CBE3E1" }}>
                    <div>Is ReferralUser</div>
                    <div 
                    onClick={clearIsReferralUser}
                    className='ml-2 mr-2 text-xs cursor-pointer'>x</div>
                </div>
            )}
            {startDate && endDate && (
                <div className='mr-2 rounded-full py-1 px-2 flex items-center' style={{ color: "#59817D", backgroundColor: "#CBE3E1" }}>
                    <div>{moment(startDate).format("DD MMM YYYY")} - {moment(endDate).format("DD MMM YYYY")}</div>
                    <div 
                    onClick={clearDate}
                    className='ml-2 mr-2 text-xs cursor-pointer'>x</div>
                </div>
            )}
            {department && (
                <div className='mr-2 rounded-full py-1 px-2 flex items-center' style={{ color: "#59817D", backgroundColor: "#CBE3E1" }}>
                    <div>{department}</div>
                    <div 
                      onClick={clearDepartment}
                    className='ml-2 mr-2 text-xs cursor-pointer'>x</div>
                </div>

            )}
            {market && (
                <div className='mr-2 rounded-full py-1 px-2 flex items-center' style={{ color: "#59817D", backgroundColor: "#CBE3E1" }}>
                    <div>{market}</div>
                    <div 
                      onClick={clearMarket}
                    className='ml-2 mr-2 text-xs cursor-pointer'>x</div>
                </div>
            )}
        </div>

    )
}
export default FilterValue