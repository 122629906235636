import {
  REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH,
  REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG,
  REACT_APP_METABASE_CLINICAL_INSIGHTS,
  REACT_APP_METABASE_FWD_METRICS,
  REACT_APP_METABASE_AIA_METRICS,
  REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE,
  REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED,
  REACT_APP_METABASE_AIA_AVG_DASS_SCORES,
  REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT,
  REACT_APP_METABASE_AIA_BREAKDOWN_GENDER,
  REACT_APP_METABASE_AIA_BREAKDOWN_AGE,
  REACT_APP_METABASE_AIA_TOP_CONCERT,
} from "constants/megabase";

export const get_params: any = (
  question_id: any,
  start_date: any,
  end_date: any,
  department: any,
  market: any,
  is_dependent: any,
  partner_id: any,
  year: any,
  month_quarter: any
) => {
  switch (question_id) {
    case REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH:
    case REACT_APP_METABASE_CLINICAL_INSIGHTS:
    case REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG:
      return {
        question_id: Number(question_id),
        start_date: start_date,
        end_date: end_date,
        market: market || "default",
        is_dependent: is_dependent,
        partner_id: partner_id,
      };
      break;
    case REACT_APP_METABASE_FWD_METRICS:
    case REACT_APP_METABASE_AIA_METRICS:
      return {
        question_id: Number(question_id),
        market: market || "default",
        is_dependent: is_dependent,
        partner_id: partner_id,
      };
      break;
    case REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE:
      return {
        question_id: Number(question_id),
        market: market || "default",
        is_dependent: is_dependent,
        partner_id: partner_id,
        department: department || "default",

      };
      break;
    case REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED:
    case REACT_APP_METABASE_AIA_AVG_DASS_SCORES:
    case REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT:
    case REACT_APP_METABASE_AIA_TOP_CONCERT:
    case REACT_APP_METABASE_AIA_BREAKDOWN_GENDER:
    case REACT_APP_METABASE_AIA_BREAKDOWN_AGE:
      return {
        question_id: Number(question_id),
        year: `${year}`,
        month: `${month_quarter}`
      };
      break;
    default:
      return {
        question_id: Number(question_id),
        start_date: start_date,
        end_date: end_date,
        department: department || "default",
        market: market || "default",
        is_dependent: is_dependent,
        partner_id: partner_id,
      };
      break;
  }
};
