import OverViewCard1 from 'containers/Overview/Components/OverViewCard1'
import OverViewCard2 from 'containers/Overview/Components/OverViewCard2'
import { useSelector } from "react-redux";
import SectionLabel from 'components/Section'
import { selectAuth } from 'containers/SingIn/authSlice';
import { isEmpty } from 'lodash';
import DepartmentHeath from 'containers/Overview/Components/DepartmentHeath';
import Chart from 'components/Chart';
import {
    REACT_APP_METABASE_AGE_BREAKDOWN,
    REACT_APP_METABASE_CLINICAL_INSIGHTS,
    REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH,
    REACT_APP_METABASE_FEEDBACK_ON_TF_PROFESSIONAL,
    REACT_APP_METABASE_FREQUENCY_NEGATIVE_MOOD_REASONS,
    REACT_APP_METABASE_FREQUENCY_POSITIVE_MOOD_REASONS,
    REACT_APP_METABASE_FWD_METRICS,
    REACT_APP_METABASE_AIA_METRICS,
    REACT_APP_METABASE_GENDER_BREAKDOWN,
    REACT_APP_METABASE_LOCATION_BREAKDOWN,
    REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG,
    REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE,
    REACT_APP_METABASE_MOOD_BREAKDOWN,
    REACT_APP_METABASE_MOOD_HEATMAP,
    REACT_APP_METABASE_MOOD_OBSERVATION_GROUPED_BY_MOOD,
    REACT_APP_METABASE_REASON_MOOD,
    REACT_APP_METABASE_TOP_CONCERNS_BREAKDOWN,
    REACT_APP_METABASE_USER_ACQUISITION,
    REACT_APP_METABASE_USER_OUTCOME_BREAKDOWN
} from 'constants/megabase'
import PieChartView from 'components/PieChart';
import LearningPacks from 'containers/AppUsage/LearningPacks';
import SectionCenterLabel from 'components/SectionCenter';
import ListProgramsTable from 'containers/ActivationPrograms/Sections/ListPrograms';
import Summary from 'containers/ActivationPrograms/Sections/Summary';
import { useHistory } from "react-router";
import { useEffect } from 'react';
import ActivationProgramsItem from "containers/ActivationPrograms/pages/ActivationProgramsItem";
import { ten_years_ago, today } from 'utils';
import { useLocation } from 'react-router-dom';
import queryString from "query-string";
import moment from 'moment';
import FilterValue from 'containers/Layout/FilterBar/filter_value';

const ExportPdf = () => {
    const history = useHistory()
    useEffect(() => {
        // history.push({ state: { isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true } });

    }, [])

    const location = useLocation();

    const {
        program,
        org,
        webinar_summary,
        app,
        departmental_health_overview,
        is_dependent,
        month,
        is_fwd_org,
        is_aia_org,
        year,
        total_user_do_dass_this_month,
        total_user_of_org_do_dass_last_month,
        total_user_of_org_do_dass_this_month,
        webinars,
        recommendations,
        top_learn,
        org_department_users,
        org_market_users,
        countries,
        market,
        department,
        is_referral_user,
        video_therapy_session_count
    } = useSelector(selectAuth);

    const decode_and_parse: any = (key: string, defaultValue: string) => {
        const value = new URLSearchParams(location.search).get(key) || defaultValue;
        return JSON.parse(decodeURIComponent(value))
    }

    const params: any = queryString.parse(location.search);
    let { startDate, endDate } =
        params || {};
    let start_date = startDate ? startDate : null;

    let end_date = endDate ? endDate : null;

    const webinarsView = webinar_summary && !isEmpty(webinar_summary.type) && webinar_summary.type.map((item: any, index: number) => {
        for (const [key, value] of Object.entries(item)) {
            return <OverViewCard1
                key={'OverViewCard1-' + index}
                className={'bg-white shadow sm:rounded-lg flex-1'}
                title={key} value={value || 0}
                description={"Webinars"}
                colorTitle={"#004876"}
                bgDescription={"rgba(234, 250, 247, 0.4)"}
            />
        }
        return null

    })

    const dataOverview_1 =
        [
            {
                key: 'OverViewCard1-2',
                className: 'bg-white shadow sm:rounded-lg flex-1',
                title: '1ST LEVEL OF SUPPORT',
                value: program?.level_1 || 0,
                unit: "headcount",
                subtitle: "Preventive Care​",
                description: 'ThoughtFullChat App Coaching',
                listSubDescription: ["Unlimited text & audio messages​",
                    "Asynchronous response within 24 hours Mon-Fri"],
                bgDescription: "rgba(234, 250, 247, 0.5)",
                colorTitle: "#004876",
                fontWeight: 600
            },
            {
                key: 'OverViewCard1-3',
                className: 'bg-white shadow sm:rounded-lg flex-1',
                title: '2ND LEVEL OF SUPPORT',
                value: program?.level_2 || 0,
                unit: "sessions",
                subtitle: "Curative Care​",
                description: 'Individual Therapy/ Counselling Sessions',
                listSubDescription: ["60 minute video therapy sessions"],
                bgDescription: "rgba(250, 249, 234, 0.5)",
                colorTitle: "#004876",
                fontWeight: 600
            },
            {
                key: 'OverViewCard1-4',
                className: 'bg-white shadow sm:rounded-lg flex-1',
                title: '3RD LEVEL OF SUPPORT',
                subtitle: "Crisis Intervention",
                value: program?.level_3 || 0,
                unit: "calls",
                description: '24/7 Wellness Hotline & Crisis Intervention',
                listSubDescription: ["Telephone psychological first aid and triaging"],
                bgDescription: "rgba(250, 234, 234, 0.5)",
                colorTitle: "#004876",
                fontWeight: 600
            }
        ]
    const utilisation = [
        // {
        //     key: "OverViewCard2-1",
        //     percent: true,
        //     className: 'bg-white shadow sm:rounded-lg flex-1',
        //     value: app?.first_time_engaging_with_mental_health,
        //     description: 'First time engaging with mental health services',
        // },
        {
            total_active_user: app?.total_active_user,
            key: 'OverViewCard2-2',
            percent: true,
            className: 'bg-white shadow sm:rounded-lg flex-1',
            value: org?.code == "MONKEY_1" ? "70" : app?.app_rate,
            description: 'App Utilisation Rate Across the Organisation',
        },
        {
            total_active_coachees: app?.total_active_coachees,
            key: 'OverViewCard2-3',
            percent: true,
            className: 'bg-white shadow sm:rounded-lg flex-1',
            value: app?.engaging_with_mental_health_course,
            description: 'Are engaging with mental health coaches',
        },
        {
            total_active_coachees: app?.total_active_coachees,
            key: 'OverViewCard2-3',
            percent: true,
            className: 'bg-white shadow sm:rounded-lg flex-1',
            value: app?.self_serve_users_percent,
            description: 'have engaged with a self-awareness tool',
        }
    ]

    type NumberType = {
        name: string,
        depression: string,
        anxiety: string,
        stress: string
    }
    const people: NumberType[] = [
        { name: 'Normal', depression: '0-9', anxiety: '0-7', stress: '0-14' },
        { name: 'Mild', depression: '10-13', anxiety: '8-9', stress: '15-18' },
        { name: 'Moderate', depression: '14-20', anxiety: '10-14', stress: '19-25' },
        { name: 'Severe', depression: '21-27', anxiety: '15-19', stress: '26-33' },
        { name: 'Extremely Severe', depression: '28', anxiety: '20', stress: '34' },
        // More people...
    ]

    const question_monthly_dass = department ? REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE : REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG
    // return null;

    const render_activation_programs_item = () => {
        return webinars?.map((webinar: any) => {
            return (
                <ActivationProgramsItem id={webinar.id} is_pdf_page={true} />
            )
        })
    }

    return (
        <>
            <main>
                <div className="py-6 bg-grey min-h-screen">
                    <FilterValue />
                    <div className=" mx-auto px-1 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-semibold text-blue">Overview of {org?.name}'s Mental Wellbeing Program</h1>

                        {start_date && end_date && (
                            <>
                                <br />
                                <p
                                    style={{ color: "rgb(89, 129, 125)" }}
                                    className="text-sm text-blue">Exported date range: {moment(start_date).format("DD MMM YYYY")} - {moment(end_date).format("DD MMM YYYY")}</p>

                            </>

                        )}

                        {
                            !is_dependent && !is_referral_user && (
                                <>
                                    <div className="mt-5">
                                        <SectionLabel key={'SectionLabel-1'} title={`Total Available Support Coverage`} />

                                    </div>
                                    <div className="flex flex-wrap justify-between space-x-8 ">
                                        {
                                            dataOverview_1.map(data => (
                                                <OverViewCard1
                                                    key={data.key}
                                                    className={data.className}
                                                    title={data.title}
                                                    value={data.value}
                                                    unit={data.unit}
                                                    subtitle={data.subtitle}
                                                    description={data.description}
                                                    listSubDescription={data.listSubDescription}
                                                    bgDescription={data.bgDescription}
                                                    colorTitle={data.colorTitle}
                                                    fontWeight={data.fontWeight}
                                                />
                                            ))
                                        }
                                    </div>
                                </>
                            )
                        }

                    </div>
                    {
                        !is_dependent && !is_referral_user && (
                            <>
                                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
                                    <SectionLabel key={'SectionLabel-2'} title={`Total Available Activation Programs`} />
                                    <div className="flex flex-wrap justify-between space-x-8 ">
                                        {webinarsView}
                                    </div>
                                </div>
                            </>
                        )
                    }

                    {
                        !is_referral_user && (
                            <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
                                <SectionLabel key={'SectionLabel-3'} title="ThoughtFullChat App Utilisation" />
                                <div className="flex flex-wrap justify-between space-x-8 ">
                                    {utilisation.map(data => (
                                        <OverViewCard2
                                            key={data.key}
                                            percent={data.percent}
                                            className={data.className}
                                            value={data.value}
                                            description={data.description}
                                            total_active_user={data.total_active_user}
                                            total_active_coachees={data.total_active_coachees}
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    }

                    {
                        !is_dependent && !is_referral_user && (
                            <>
                                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10 ">
                                    <SectionLabel
                                        tooltip={"Note: Only departments with more than 10 active users will be displayed"}
                                        key={'SectionLabel-3'} title="Departmental Health" />
                                    <div className="flex flex-wrap justify-between space-x-8 mb-10">
                                        <DepartmentHeath departmental_health_overview={departmental_health_overview} />
                                    </div>
                                </div>
                                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10 flex">
                                    <div className="w-1/2 ">
                                        <SectionLabel key={'SectionLabel-3'} title="Individual Therapy/ Counselling Sessions Utilisation" />
                                        <div className="flex flex-wrap justify-between space-x-8 ">
                                            <div className={"bg-white shadow sm:rounded-lg flex-1"}>
                                                <div className="px-4 py-5 sm:p-6 bg-white">

                                                    <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                                                        <p>{org?.code == "MONKEY_1" ? 151 : (video_therapy_session_count || video_therapy_session_count == 0 ? video_therapy_session_count : "-")}
                                                            <span style={{ color: "#59817D" }} className="text-sm font-normal ml-2">
                                                                sessions were utilised
                                                            </span></p>

                                                    </div>
                                                </div>

                                            </div >
                                        </div>
                                    </div>
                                    <div className="w-1/2  ml-6 pb-8">
                                        <SectionLabel key={'SectionLabel-3'} title="24/7 Wellness Hotline & Crisis Intervention Utilisation" />
                                        <div className="flex flex-wrap justify-between space-x-8 ">
                                            <div className={"bg-white shadow sm:rounded-lg flex-1"}>
                                                <div className="px-4 py-5 sm:p-6 bg-white">

                                                    <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                                                        <p>{program?.level_3_report || program?.level_3_report == 0 ? program?.level_3_report : "-"}
                                                            <span style={{ color: "#59817D" }} className="text-sm font-normal ml-2">
                                                                calls were made
                                                            </span></p>

                                                    </div>
                                                </div>

                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        !!is_fwd_org && !is_dependent && (
                            <div className=" mx-auto px-1 sm:px-6 md:px-8">
                                <div className="flex flex-wrap justify-between space-x-8 pt-8 ">
                                    <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_FWD_METRICS} />
                                </div>
                            </div>
                        )
                    }
                    {
                        !!is_aia_org && !is_dependent && (
                            <div className=" mx-auto px-1 sm:px-6 md:px-8">
                                <div className="flex flex-wrap justify-between space-x-8 pt-8 ">
                                    <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_AIA_METRICS} />
                                </div>
                            </div>
                        )
                    }

                </div>
            </main>

            <main id="main-user-demo-graphics">

                <div className="py-6 bg-grey">
                    <div className="mx-auto px-4 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-bold text-blue mb-4">User Demographics</h1>
                    </div>
                    <div className="py-4">
                        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <div className="justify-between">
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_USER_ACQUISITION} />
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_LOCATION_BREAKDOWN} />
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_TOP_CONCERNS_BREAKDOWN} />
                            </div>
                        </div>

                        <div className="mx-auto px-4 sm:px-6 md:px-8">
                            <div className="justify-between">
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_GENDER_BREAKDOWN} />
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 mt-6'} question={REACT_APP_METABASE_AGE_BREAKDOWN} />
                            </div>
                        </div>
                    </div>
                </div>
            </main >

            <main>
                <div className="py-6 bg-grey">
                    <div className="mx-auto px-4 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-bold text-blue mb-4">App Usage</h1>
                    </div>
                    <div className="py-4">
                        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <SectionLabel title="Engagement" />
                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <PieChartView className={'bg-white shadow sm:rounded-lg flex-1'} title={'In-App Sections'} />
                            </div>
                        </div>
                        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <SectionLabel title="Learning Packs" />
                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <LearningPacks rating={app?.app_rating?.toFixed(1)} className={'bg-white shadow sm:rounded-lg flex-1'} title={'Top Learning Packs'} />
                            </div>
                        </div>
                        <div className="mx-auto px-4  sm:px-6 md:px-8 mb-10">
                            <SectionLabel title="Mood Summary" />

                            <div className="flex flex-wrap justify-between space-x-8  mb-10">
                                <Chart
                                    height={"600px"}
                                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                                    question={REACT_APP_METABASE_MOOD_BREAKDOWN} />
                            </div>

                            <div className="flex flex-wrap justify-between space-x-8  mb-10">
                                <Chart
                                    height={"600px"}
                                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                                    question={REACT_APP_METABASE_FREQUENCY_POSITIVE_MOOD_REASONS} />
                            </div>

                            <div className="flex flex-wrap justify-between space-x-8  mb-10">
                                <Chart
                                    height={"600px"}
                                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                                    question={REACT_APP_METABASE_FREQUENCY_NEGATIVE_MOOD_REASONS} />
                            </div>

                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <Chart
                                    description="Mood observation grouped by mood"
                                    height={"600px"}
                                    className={'bg-white shadow sm:rounded-lg flex-1'}
                                    question={REACT_APP_METABASE_MOOD_OBSERVATION_GROUPED_BY_MOOD} />
                            </div>
                        </div>




                        <div className="mx-auto px-4 pt-10 sm:px-6 md:px-8">
                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <Chart
                                    description="Frequency of mood based on days"
                                    height={"780px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_MOOD_HEATMAP} />
                            </div>
                        </div>
                        <div className="mx-auto px-4 pt-10 sm:px-6 md:px-8 mb-10">
                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <Chart
                                    description="Frequency of reasons based on moods"
                                    height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_REASON_MOOD} />
                            </div>
                        </div>
                        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <SectionLabel title="User Feedback" />
                            <div className="">

                                <Chart
                                    description="User outcome breakdown"
                                    height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1 h-150 overflow-hidden '} question={REACT_APP_METABASE_USER_OUTCOME_BREAKDOWN} />
                                {/* <RatingBar rating={app?.app_rating?.toFixed(1)} className={'bg-white shadow sm:rounded-lg flex-1 mt-12'} title={'App Rating'} /> */}
                            </div>
                        </div>
                        <div className="mx-auto px-4 sm:px-6 md:px-8">
                            <div className="flex flex-wrap justify-center">
                                <Chart
                                    description="Feedback on TF Professional"
                                    height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_FEEDBACK_ON_TF_PROFESSIONAL} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <main>
                <div className="py-6 bg-grey">
                    <div className="mx-auto px-4 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-bold text-blue mb-4">Clinical Insights</h1>
                    </div>
                    <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10 items-center justify-center">
                        <span className="font-normal text-xl text-blue" >{'The Depression, Anxiety and Stress Scale (DASS-21) is a set of three self-report scales designed to measure the emotional states of depression, anxiety and stress.'}</span>
                    </div>
                    <div className="py-4">
                        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH} />
                            </div>

                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_CLINICAL_INSIGHTS} />
                            </div>



                            <div className="flex flex-wrap justify-between space-x-8 pt-8 ">
                                <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={question_monthly_dass} />
                            </div>
                        </div>
                        <div className="flex flex-col mx-auto px-4 sm:px-6 md:px-8">
                            <SectionCenterLabel title={"Interpretation of DASS Scores"} />
                        </div>

                        <div className="flex flex-wrap justify-between space-x-8 mx-8 bg-white shadow sm:rounded-lg flex-1 ">
                            <div className="w-full mx-auto">
                                <div className="flex flex-wrap justify-between space-x-8  ">
                                    <div className="w-full flex flex-col">
                                        <div className="w-full -my-2 overflow-x-auto">
                                            <div className="w-full py-2 align-middle inline-block ">
                                                <div className="w-full shadow overflow-hidden">
                                                    <table className="w-full divide-y divide-grey1">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Meaning
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Depression
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Anxiety
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                >
                                                                    Stress
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {people.map((person: NumberType, personIdx) => (
                                                                <tr key={person.name} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.depression}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.anxiety}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.stress}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {!is_referral_user && (
                <main>
                    <div className="py-6 bg-grey">
                        <div className="mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-blue mb-4">Activation Programs</h1>
                        </div>
                        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <div className="flex flex-wrap justify-between space-x-8  ">
                                <Summary className={'bg-white shadow sm:rounded-lg flex-1'} title={'Summary'} />
                            </div>
                        </div>
                        {render_activation_programs_item()}
                        {/* <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                            <SectionLabel title="List of Programs " />
                            <div className="flex flex-wrap justify-between space-x-8 bg-white shadow sm:rounded-lg flex-1 ">
                                <ListProgramsTable data={webinars} />
                            </div>
                        </div> */}

                    </div>
                </main>

            )}

        </>
    )
}

export default ExportPdf