import SectionLabel from 'components/Section'
import { useLocation } from 'react-router-dom';
import LearningPacksPdf from './LearningPacksPdf';

const A = () => {

    const location = useLocation();
    const decode_and_parse: any = (key: string, defaultValue: string) => {
        const value = new URLSearchParams(location.search).get(key) || defaultValue;
        return JSON.parse(decodeURIComponent(value))
    }
    const app = decode_and_parse("app", "{}");
    const top_learn = decode_and_parse("top_learn", "{}");
    return (
            // myiFrame id is needed to be able to print to the file (can refer to the lambda function)

        <main id="myiFrame">
            <div className="CardVisualization py-4">
                <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
                    <SectionLabel title="Learning Packs" />
                    <div className="flex flex-wrap justify-between space-x-8  ">
                        <LearningPacksPdf top_learn={top_learn} rating={app?.app_rating?.toFixed(1)} className={'bg-white shadow sm:rounded-lg flex-1'} title={'Top Learning Packs'} />
                    </div>
                </div>

            </div>
        </main>
    )
}

export default A