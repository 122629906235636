

import ActivationPrograms from "containers/ActivationPrograms";
import ActivationProgramsItem from "containers/ActivationPrograms/pages/ActivationProgramsItem";
import AppUsage from "containers/AppUsage";
import ClinicalInsights from "containers/ClinicalInsights";
import Layout from "containers/Layout";
import Overview from "containers/Overview";
// import Recommendations from "containers/Recommendations";
import UserDemographics from "containers/UserDemographics";
import ManageEmployees from "containers/ManageEmployees";
import { Switch, Route } from "react-router-dom";
import ExportPdf from "containers/ExportPdf";
import ThoughtfullAiaCs from "containers/ThoughtfullAiaCs";
import AiaCs from "containers/AiaCs";

const MainApp = () => {
  return (
    <Layout>
      <Switch>

        <Route path='/overview' component={Overview} />
        {/* <Route path="/recommendations" component={Recommendations} /> */}
        <Route path="/activation-programs" component={ActivationPrograms} />
        <Route path="/activation-program/:id" component={ActivationProgramsItem} />
        <Route path="/clinical-insights" component={ClinicalInsights} />
        <Route path="/app-usage" component={AppUsage} />
        <Route path="/user-demographics" component={UserDemographics} />
        <Route path="/manage-employees" component={ManageEmployees} />
        <Route path="/export-pdf" component={ExportPdf} />
        <Route path="/tf-aia-cs" component={AiaCs} />

      </Switch>
    </Layout>
  )
};
export default MainApp;