
import {
  REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH
} from 'constants/megabase'
import ChartPdf from '.';


const A = () => {
  return (
      <div className="bg-grey px-4">
        <div className="mx-auto pt-4">
          <h1 className="text-2xl font-bold text-blue ">Clinical Insights</h1>
        </div>
        <div className="mx-auto pt-4 items-center justify-center">
          <span className="font-normal text-xl text-blue" >{'The Depression, Anxiety and Stress Scale (DASS-21) is a set of three self-report scales designed to measure the emotional states of depression, anxiety and stress.'}</span>
        </div>
        <div className="">
          <div className="mx-auto pt-4">
            <div className="flex flex-wrap justify-between space-x-8  ">
              <ChartPdf height={"80vh"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_CLINICAL_INSIGHTS_LAST_MONTH} />
            </div>
          </div>
        </div>
      </div>
  )
}

export default A;