
import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import ToggleBtn from "components/Toggle";
import { useLocation } from 'react-router-dom';


const ReferralUserButton = (props: any) => {
  const location = useLocation();
  const { org } = useSelector(selectAuth);
    const { setIsReferralUser } = props;
    const isReferralUser = new URLSearchParams(location.search).get('isReferralUser') == "true"
    
    return (
        <>
            {
               org?.tf_partner_id && (
                  <div className="flex items-center">
                    <div 
                    style={{marginBottom: "0.5rem"}}
                    className="mr-2 mt-2">
                      <ToggleBtn enabled={isReferralUser} setEnabled={setIsReferralUser} />

                    </div>
                    <div
                    style={{marginBottom: "0.5rem"}}
                    className="ml-4 font-bold max-w-xl text-lg text-primary items-center">
                      Referral Users
                    </div>
                  </div>

                )
              }
        </>
    )
}

export default ReferralUserButton;