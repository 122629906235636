


import Summary from './Sections/Summary'
import ListProgramsTable from './Sections/ListPrograms'
import SectionLabel from 'components/Section'
import { useSelector } from 'react-redux'
import { selectAuth } from 'containers/SingIn/authSlice'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { add_params_and_push } from 'utils'
import FilterValue from 'containers/Layout/FilterBar/filter_value'
const ActivationPrograms = () => {
  const { webinars } = useSelector(selectAuth);

  const history = useHistory()
  const location = useLocation();

  useEffect(() => {
    add_params_and_push({ typeFilterDatetime: 0 }, location, queryString, history)

  }, []);
  return (
    <main>
      <div className="py-6 bg-grey">
        <FilterValue />
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-blue mb-4">Activation Programs</h1>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div className="flex flex-wrap justify-between space-x-8  ">
            <Summary className={'bg-white shadow sm:rounded-lg flex-1'} title={'Summary'} />
          </div>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <SectionLabel title="List of Programs " />
          <div className="flex flex-wrap justify-between space-x-8 bg-white shadow sm:rounded-lg flex-1 ">
            <ListProgramsTable data={webinars} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default ActivationPrograms