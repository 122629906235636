
import { toNumber } from 'lodash';

import IconTooltip from 'components/IconTooltip';
type SummaryItemProps = {
    rate: any
    title: string
    color: string
    subDes?: string
    tooltip?: string
    index?: number
}
const SummaryItem = (props: SummaryItemProps) => {
    return (
        <div className={'flex flex-col items-center flex-1 mt-5'}>
            <div className={'relative flex rounded-full w-12 h-12 px-20 py-20 mb-5 items-center justify-center ' + props.color}>
                <div className={'text-3xl font-bold text-white'}>
                    {toNumber(props.rate) % 1 > 0 ? toNumber(props.rate).toFixed(1) : (props.rate || 0)}
                </div>
                {!!(props.subDes && props.rate) && (
                    <div className={'text-base text-center font-normal text-white w-full absolute bottom-10'}>
                        <span>
                            {props.subDes}
                        </span>
                    </div>)}

            </div>
            <div
                className={'flex justify-between items-start text-sm font-normal text-gray text-center'}>
                <div className="">
                    {props.title}
                </div>
                {
                    props.tooltip && (
                        <div className="ml-1">
                            <IconTooltip
                                dataFor={`${props.index}dataFor`}
                                title={props.tooltip}
                            />

                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default SummaryItem