const ApplyButton = (props: any) => {
    const onClose = () => {
        props?.setFilterbarClose(false)
    }
    
    const apply = () => {
       
        onClose()
        props?.apply()
    }
    return (
        <div 
        style={{borderTop: "1px solid #D1D5DB"}}
        className="flex absolute bottom-0 right-0 w-full p-4 justify-end ">
            <div 
            onClick={onClose}
            style={{borderColor: "#D1D5DB"}}
            className="border rounded-lg mr-4 py-2 px-4 text-sm cursor-pointer">
                Cancel
            </div>
            <div 
            onClick={apply}
            style={{background: "#009383"}} className="rounded-lg text-white py-2 px-4 text-sm cursor-pointer">
               Apply
            </div>
        </div>

    )
}
export default ApplyButton