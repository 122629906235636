

import Summary from 'containers/ActivationPrograms/Sections/Summary'
import SectionCenterLabel from 'components/SectionCenter'
import { Link, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'


const ActivationProgramsItemPdf = (props: any) => {
  const location = useLocation();

  const decode_and_parse: any = (key: string, defaultValue: string) => {
    const value = new URLSearchParams(location.search).get(key) || defaultValue;
    return JSON.parse(decodeURIComponent(value))
  }
  const webinar = decode_and_parse("webinar", "[]");

  return (
    // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
    <main id="myiFrame">
      <div className="py-6 bg-grey">

        <div className="flex flex-col mx-auto px-4 sm:px-6 md:px-8">
          <SectionCenterLabel center title={webinar?.title || ""} />
        </div>
        <div className="flex flex-col mx-auto px-4 sm:px-6 md:px-8 mb-10 items-center justify-center">
          <span className="font-normal text-xl text-blue" >{moment(webinar?.begin_at).format("DD MMMM YYYY") || ""}</span>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div className="flex flex-wrap justify-between space-x-8  ">
            <Summary className={'bg-white shadow sm:rounded-lg flex-1'} title={'Webinar Overview'} webinar={webinar} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default ActivationProgramsItemPdf