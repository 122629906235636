

import { useState, useEffect } from "react";
import moment from 'moment'
import BoxTitle from "components/BoxTitle";
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { selectAuth } from "containers/SingIn/authSlice";
import { useSelector } from "react-redux";
import { add_params_and_push, ten_years_ago, today } from "utils";
import { get_params } from "components/Chart/param";

const ChartPdf = (props: any) => {
    const [token, setToken] = useState('')
    const { question, className, title, height } = props
    const [width] = useState(window.innerWidth > 986 ? (window.innerWidth - 450) / 2 : window.innerWidth - 50)

    const location = useLocation();
    const { typeFilterDatetime }
        = useSelector(selectAuth);
    const query = queryString.parse(location.search);
    
  const history = useHistory()

  useEffect(() => {
    add_params_and_push({typeFilterDatetime: 0}, location, queryString, history)

  }, []);
    const access_token = `${query.access_token}` || "";
    const department = `${query.department}` || "";
    const market = `${query.market}` || "";
    const month: any =  `${query.month}` || "12/2023";
    const year = Number(query.year) || 2023;
    const total_user_do_dass_this_month = Number(query.total_user_do_dass_this_month) || 0;
    const total_user_of_org_do_dass_last_month = Number(query.total_user_of_org_do_dass_last_month) || 0;
    const total_user_of_org_do_dass_this_month = Number(query.total_user_of_org_do_dass_this_month) || 0;
    const is_dependent = (query.is_dependent) == "true";
    const is_apply_filter = new URLSearchParams(location.search).get('is_apply_filter');
    const tf_partner_id = new URLSearchParams(location.search).get('tf_partner_id') || "default";

    const _year = new URLSearchParams(location.search).get('year') || year;
    const month_quarter = new URLSearchParams(location.search).get('month_quarter')|| "Entire year";

    let start_date: any = new URLSearchParams(location.search).get('startDate') ? moment(new URLSearchParams(location.search).get('startDate')).toDate() : ten_years_ago;
    let end_date: any = new URLSearchParams(location.search).get('endDate') ? moment(new URLSearchParams(location.search).get('endDate')).toDate() : today;
   
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            //"Access-Control-Allow-Origin": "*",
        }
    });

    instance.interceptors.request.use(function (config: any) {
        // Do something before request is sent
        if (access_token && config.headers) {
            config.headers["x-access-token"] = access_token;
        }
        return config;
    }, function (error: any) {
        // Do something with request error
        return Promise.reject(error);
    });
    const getMetabaseToken = async (data: {
        end_date?: string,
        question_id: number | string,
        start_date: string,
        department?: string,
        market?: string,
        is_dependent?: boolean,
        partner_id?: string;
    }) => {

        try {
            const res = await instance.post("/hr/v1/metabase/", data)
            return res
        } catch (error: any) {
            console.log('error', error)
            if (error.response && error.response.data) {
                return error.response.data
            }
            return {
                error: "There is an exception error !"
            }
        }
    }

    const showLabelBottom = () => [30, 31, 376, 377].includes(Number(question))
    const getMetabaseTokenTask = async () => {
        let startDate = moment(start_date).format('YYYY-MM-DD');
        let endDate = moment(end_date).format('YYYY-MM-DD');
        
        let params = get_params(
            question,
            startDate,
            endDate,
            department,
            market,
            is_dependent,
            tf_partner_id,
            _year,
            month_quarter
        )
        const res: any = await getMetabaseToken(params)

        if (res.data && res.data.data.token) {
            setToken(res.data.data.token)
        }
    }
    useEffect(() => {
        getMetabaseTokenTask()
    }, [is_apply_filter]);

    let _month = moment(month, "MM/YYYY")

    const renderBottom = () => {
        const textMonth: { [key: string]: string } = {
            "1": "January",
            "2": "February",
            "3": "March",
            "4": "April",
            "5": "May",
            "6": "June",
            "7": "July",
            "8": "August",
            "9": "September",
            "10": "October",
            "11": "November",
            "12": "December"
        }
        let thisMonth = Number(moment().format("MM"))
        let preMonth = thisMonth - 1
        let _year = year

        if (thisMonth == 1) {
            preMonth = 12
            _year = year - 1
        }

        let textThisMonth = `${textMonth[`${thisMonth}`]}, ${year}`
        let textLastMonth = `${textMonth[`${preMonth}`]}, ${_year}`

        if ([31, 377].includes(Number(question))) {
            textThisMonth = "All TFC Users"
            textLastMonth = "My Organization"
        }
        const value_1: { [key: string]: number } = {
            "30": total_user_of_org_do_dass_last_month,
            "376": total_user_of_org_do_dass_last_month,
            "31": total_user_of_org_do_dass_this_month,
            "377": total_user_of_org_do_dass_this_month

        }

        const value_2: { [key: string]: number } = {
            "30": total_user_of_org_do_dass_this_month,
            "376": total_user_of_org_do_dass_this_month,
            "31": total_user_do_dass_this_month,
            "377": total_user_do_dass_this_month
        }
        return (
            <>
                <div><span className="text-xs ml-3" style={{ color: "#A3A3A3" }}>{textLastMonth}:</span> <span className="text-xs">{value_1[`${Number(question)}`]} users completed the DASS assessment</span>  </div>
                <div><span className="text-xs ml-3" style={{ color: "#A3A3A3" }}>{textThisMonth}:</span> <span className="text-xs">{value_2[`${Number(question)}`]} users completed the DASS assessment</span>  </div>

            </>
        )
    }

    return (
        <div className={className + " relative"}>
            <div className="remove-line w-full h-3 absolute top-0 left-0 bg-white"></div>
            <div className="remove-line w-full h-14 absolute bottom-0 left-0 bg-white items-center justify-center">
                {
                    (showLabelBottom()) && (renderBottom())
                }
            </div>
            <div className="h-full">
                {
                    (title) && <BoxTitle title={(props.title + " " + moment(_month, "MM/YYYY").format("MM/YYYY")) || ""} />
                }
                <div className="iframe-wrapper font-bold text-3xl text-primary" style={{ height: height }}>
                    <iframe
                        id={`myiFrame`}
                        title={`${question}`}
                        key={question}
                        className={`${props?.is_not_full ? "" : "w-full"} h-full`}
                        src={process.env.REACT_APP_METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true/"}
                        frameBorder="0"
                        width={width}
                    ></iframe>
                </div>
            </div>

        </div>
    )
}

export default ChartPdf
