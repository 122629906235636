/* This example requires Tailwind CSS v2.0+ */

import react, { useState, useEffect } from 'react'
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
// import { typeOTP } from "AppRedux/actions/Auth";
// import { CLEAR_OTP } from "AppRedux/ActionTypes";
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { clearMessOTP, selectAuth } from 'containers/SingIn/authSlice';
import { sendOTPAction } from 'containers/SingIn/authAPI';

const TypeOTP = (props: any) => {
    const dispatch = useDispatch();
    const [otp, setOTP] = useState('')
    const { useremail, messageOTP } = useSelector(selectAuth);

    const submitOTP = () => {
        dispatch(sendOTPAction({ otp, useremail, history: props.history }))
    }
    const onFocus = () => {
        dispatch(clearMessOTP());
    }
    return (
        <>
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray500">
                    OTP
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                        id="otp"
                        name="otp"
                        type="number"
                        onFocus={onFocus}
                        value={otp}
                        onKeyPress={(e) => e.key === 'Enter' && submitOTP()}
                        onChange={(evt) => setOTP(evt.target.value)}
                        placeholder="6 digit OTP code was sent to your email address"
                        autoComplete="otp"
                        required
                        className={`${messageOTP && "input-error"} appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        aria-invalid="true"
                        aria-describedby="email-error"
                    />
                    {
                        messageOTP === "" ? null :
                            <div className={`${messageOTP && "text-error"} absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`}>
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                    }

                </div>
                <p className="mt-2 text-sm text-red-600 text-error">
                    {messageOTP}
                </p>
            </div>
            <div>
                <button
                    onClick={submitOTP}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-normal text-white bg-primary hover:bg-primaryHeavy focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Verify OTP
                </button>
            </div>
        </>
    )
}
export default TypeOTP