import { Fragment, useEffect, useRef, useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { Transition, Menu } from "@headlessui/react";
import moment from "moment";
import DatePicker from "react-datepicker";

import { useHistory, useLocation } from "react-router-dom";
import {
    applyDate,
    applyYear,
    changeProgram,
    selectAuth,
    userSignOutAction,
    changeDepartment,
    changeIsDependent,
    changeMarket,
    applyTypeFilterDatetime,
    changeIsReferralUser
} from "containers/SingIn/authSlice";
import {
    doExportPdf,
    fetchListProgramsAction,
    refreshProgramAction,
    doUploadDataToS3
} from "containers/SingIn/authAPI";
import DepartmentButton from "containers/Layout/Topbar/department_button";
import DependentButton from "containers/Layout/Topbar/dependent_button";
import ReferralUserButton from "containers/Layout/Topbar/referral_user_button";
import MarketButton from "containers/Layout/Topbar/market_button";
import ProgramButton from "containers/Layout/Topbar/program_button";
import DatetimeButton from "containers/Layout/Topbar/datetime_button";
import ApplyButton from "containers/Layout/Topbar/apply_button";
import {
    DownloadIcon
} from '@heroicons/react/outline';
import get_pages from "containers/Layout/utils"
import { add_params_and_push, getToken } from "utils";
import queryString from "query-string";
import FloatingButton from "components/FloatingButton";
import YearPicker from "./year_picker";
import MonthQuarter from "./month_quarter";
const { v4: uuidv4 } = require('uuid');
const { PDFDocument } = require('pdf-lib');

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function TopbarAiaCs(props: any) {
    const history = useHistory()
    const location = useLocation();
    const { user,
        program,
        org,
        webinar_summary,
        app,
        departmental_health_overview,
        is_dependent,
        month,
        is_fwd_org,
        is_aia_org,
        year,
        total_user_do_dass_this_month,
        total_user_of_org_do_dass_last_month,
        total_user_of_org_do_dass_this_month,
        webinars,
        recommendations,
        top_learn,
        org_department_users,
        org_market_users,
        countries,
        market,
        department,
        video_therapy_session_count } = useSelector(selectAuth);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [isDependent, setIsDependent] = useState(Boolean(new URLSearchParams(location.search).get('is_dependent')))
    const [isReferralUser, setIsReferralUser] = useState(Boolean(new URLSearchParams(location.search).get('isReferralUser')))
    const openDateMenu = useRef<any>(null);
    const dispatch = useDispatch();
    const signOut = () => {
        history.push({
            pathname: "signin",
            search: location.search,
            state: location.state
        });
        dispatch(userSignOutAction());
    };

    useEffect(() => {
        dispatch(fetchListProgramsAction());
    }, []);

    useEffect(() => {
        dispatch(changeIsDependent(isDependent));
    }, [isDependent]);

    useEffect(() => {
        dispatch(changeIsReferralUser(isReferralUser));
    }, [isReferralUser]);

    // useEffect(() => {
    //   dispatch(refreshProgramAction(location.search));

    // }, []);

    const applyFilter = () => {
        const is_apply_filter = new URLSearchParams(location.search).get('is_apply_filter') == "true";
        add_params_and_push({ is_apply_filter: !is_apply_filter }, location, queryString, history)
        dispatch(refreshProgramAction(location.search));
    }
    const handleSetIsDependent = (value: any) => {
        setIsDependent(value)

        add_params_and_push({ is_dependent: value }, location, queryString, history)
    }

    const handleSetIsReferralUser = (value: any) => {
        console.log('value', value)

        setIsReferralUser(value)

        const partner_id = value ? org?.tf_partner_id : "";

        add_params_and_push({ isReferralUser: value, partner_id: partner_id }, location, queryString, history)
    }

    const onChangeProgram = (event: any) => {
        add_params_and_push({ program: event.target.value }, location, queryString, history)

        dispatch(changeProgram(event.target.value));
    };

    const applyDateFilter = (date: any) => {
        const month = moment(date).format("MM/YYYY")

        add_params_and_push({
            startDate: moment(month, "MM/YYYY").startOf('month').format('YYYY-MM-DD'),
            endDate: moment(month, "MM/YYYY").endOf('month').format('YYYY-MM-DD')
        }, location, queryString, history)

        const userData = { month: moment(date).format("MM/YYYY") };
        dispatch(applyDate(userData));
    };

    const applyYearFilter = (year: any) => {
        add_params_and_push({ year: moment(year).year() }, location, queryString, history)

        const userData = { year: moment(year).year() };
        dispatch(applyYear(userData));
    };

    const onChangeMonthQuarter = (event: any) => {
        add_params_and_push({ month_quarter: event.target.value }, location, queryString, history)
    }

    const onChangeMarket = (event: any) => {
        add_params_and_push({ market: event.target.value }, location, queryString, history)

        dispatch(changeMarket(event.target.value));
    }

    const handleClickCalendar = () => {
        if (!isOpenCalendar) {
            openDateMenu.current.onInputClick();
        }
    };
    const handleCalendarClose = () => setIsOpenCalendar(false);
    const handleCalendarOpen = () => setIsOpenCalendar(true);

    const onChangeTypeFilterDatetime = (event: any) => {

        dispatch(applyTypeFilterDatetime(event.target.value));
        if (event.target.value == 1) {
            add_params_and_push({ month: moment().format("MM/YYYY"), typeFilterDatetime: event.target.value }, location, queryString, history)

            const userData = { month: moment().format("MM/YYYY") };
            dispatch(applyDate(userData));
        }
        if (event.target.value == 2) {
            add_params_and_push({ month: 0, year: moment().year(), typeFilterDatetime: event.target.value }, location, queryString, history)

            const userData = { month: 0 };
            dispatch(applyDate(userData));
            dispatch(applyYear({ year: moment().year() }));
        }

    }
    const is_export_pdf_page = () => {
        return window.location.href.includes("export-pdf")
    }

    let start_date: any = new URLSearchParams(location.search).get('startDate');
    let end_date: any = new URLSearchParams(location.search).get('endDate');

    async function delay(milliseconds: any) {
        await new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    const exportPdf = async () => {

        const pages = get_pages({
            program,
            org,
            webinar_summary,
            app,
            departmental_health_overview,
            is_dependent,
            month,
            is_fwd_org,
            is_aia_org,
            year,
            total_user_do_dass_this_month,
            total_user_of_org_do_dass_last_month,
            total_user_of_org_do_dass_this_month,
            webinars,
            recommendations,
            top_learn,
            org_department_users,
            org_market_users,
            countries,
            market,
            department,
            start_date,
            end_date,
            video_therapy_session_count
        })

        if (pages && pages.length > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            loading(25000)
            await Promise.all(pages.map(page => retryApiCall(getBase64Pdf, { url: page }, 2)))
                .then(async (completedTasks) => {
                    console.log('completedTasks', completedTasks)
                    const pdfBase64s: any = completedTasks?.map((data: any) => {
                        return data.data?.data?.data?.data
                    })
                    console.log('pdfBase64s', pdfBase64s)
                    const data = pdfBase64s.filter((pdfBase64: any) => { return !!pdfBase64 })
                    const mergedPdfString = await mergePDFs(data)
                    if (mergedPdfString) {
                        downloadPDF(mergedPdfString)
                    }

                }).catch((error) => {
                    console.log('error', error)
                });
            cancelLoading()
        }

    }
    const mergePDFs = async (pdfStrings: any) => {
        try {

            const pdfDoc = await PDFDocument.create();

            for (const pdfBase64String of pdfStrings) {

                const pdfBytes = base64StringToBuffer(pdfBase64String);
                const pdf = await PDFDocument.load(pdfBytes);

                const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

                copiedPages.forEach((page: any) => pdfDoc.addPage(page));

            }
            const mergedPdfBytes = await pdfDoc.saveAsBase64();
            console.log('mergedPdfBytes', mergedPdfBytes)
            return mergedPdfBytes;

        } catch (error) {
            console.log('error', error)
            return null;
        }
    }
    const base64StringToBuffer = (base64String: string) => {
        // Decode the base64 string into binary data
        const binaryData = atob(base64String);

        // Create a Uint8Array from the binary data
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        // Create a buffer from the Uint8Array
        const buffer = byteArray.buffer;

        return buffer;
    }


    const downloadPDF = (pdf: string) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "tf_insights.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const retryApiCall = (apiFunction: any, args: any, maxRetries: number) => {
        return new Promise(async (resolve, reject) => {
            for (let retryCount = 0; retryCount <= maxRetries; retryCount++) {
                try {
                    const response = await apiFunction(args);
                    if (response && response.data?.data?.data?.data) {
                        resolve(response);
                        return;
                        // Exit the loop if successful
                    }
                } catch (error) {
                    if (retryCount === maxRetries) {
                        reject(error); // Reject if max retries reached
                    }
                }
            }
        });
    }

    const getBase64Pdf = async (requestBody: any) => {
        return doExportPdf(requestBody)
    }

    const uploadDataToS3 = async (requestBody: any) => {
        return doUploadDataToS3(requestBody)
    }


    const loading = (time: number) => {
        console.log("loading...")
        let spinner: any = document.getElementById("spinner");
        spinner.style.display = "block";
        let loadingOverlay: any = document.getElementById("loadingOverlay");
        loadingOverlay.style.display = "block";
        let ctx: any = spinner.getContext("2d");
        let width = spinner.width;
        let height = spinner.height;
        let degrees = 0;
        let new_degrees = 0;
        let difference = 0;
        let bgcolor = "#FFFFFF";
        let text;
        let animation_loop: any;

        function init() {
            ctx.clearRect(0, 0, width, height);

            ctx.beginPath();
            ctx.strokeStyle = bgcolor;
            ctx.lineWidth = 30;
            ctx.arc(width / 2, width / 2, 100, 0, Math.PI * 2, false);
            ctx.stroke();
            let radians = degrees * Math.PI / 180;

            ctx.beginPath();
            ctx.strokeStyle = "#009383";
            ctx.lineWidth = 30;
            ctx.arc(width / 2, height / 2, 100, 0 - 90 * Math.PI / 180, radians - 90 * Math.PI / 180, false);
            ctx.stroke();
            ctx.fillStyle = "#FFFFFF";
            ctx.font = "50px arial";
            text = Math.floor(degrees / 360 * 99) + "%";
            let text_width = ctx.measureText(text).width;
            ctx.fillText(text, width / 2 - text_width / 2, height / 2 + 15);
        }

        function draw() {
            if (typeof animation_loop != undefined) clearInterval(animation_loop);
            new_degrees = 360;
            difference = new_degrees - degrees;
            animation_loop = setInterval(animate_to, time / difference);
        }

        function animate_to() {
            if (degrees == new_degrees)
                clearInterval(animation_loop);
            else if (degrees < new_degrees)
                degrees++;
            else
                degrees--;
            init();
        }

        draw();
    }

    const cancelLoading = () => {
        console.log("cancel loading!")
        let spinner: any = document.getElementById("spinner");
        spinner.style.display = "none";
        let loadingOverlay: any = document.getElementById("loadingOverlay");
        loadingOverlay.style.display = "none";
    }

    return (
        <>
            <div
                style={{ minHeight: '4rem' }}
                className="sticky top-0 z-10 flex-shrink-0 flex bg-white shadow">

                <div
                    style={{
                        boxShadow:
                            "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
                    }}
                    className="flex-1 px-4 flex justify-between"
                >

                    <div className="flex font-bold max-w-xl text-lg text-primary items-center">

                        <p>{`ThoughtFull Insights`}</p>
                    </div>
                    <div className=" flex items-center">
                        <div className="flex flex-row flex-wrap relative rounded-md shadow-sm justify-end items-center">
                            <YearPicker
                                handleClickCalendar={handleClickCalendar}
                                handleCalendarClose={handleCalendarClose}
                                handleCalendarOpen={handleCalendarOpen}
                                openDateMenu={openDateMenu}
                                applyYearFilter={applyYearFilter}

                            />
                            <MonthQuarter onChangeMonthQuarter={onChangeMonthQuarter} />

                            <div
                                style={{ marginBottom: "0.5rem" }}
                                className="ml-1 border-2 border-grey1 rounded-md">
                                <button onClick={applyFilter} className="bg-white px-2 hover:bg-gray-100">
                                    Apply
                                </button>
                            </div>

                            {/* Profile dropdown */}
                            <Menu as="div" className="ml-3 relative">
                                <div>
                                    <Menu.Button className="mb-2 max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Open user menu</span>
                                        <div className="h-8 w-8 rounded-full bg-primary text-white flex items-center justify-center">
                                            <span className="font-semibold text-xl">
                                                {user?.email?.charAt(0)?.toUpperCase()}
                                            </span>
                                        </div>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item key={"info"}>
                                            {({ active }) => (
                                                <div>
                                                    <span
                                                        className={"block px-4 font-bold text-sm text-black"}
                                                    >
                                                        Signed in as
                                                    </span>
                                                    <button
                                                        className={" block px-4 pb-2 text-sm text-gray-700"}
                                                    >
                                                        <span>{user?.email}</span>
                                                    </button>
                                                </div>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item key={"logout"}>
                                            {({ active }) => (
                                                <button
                                                    onClick={signOut}
                                                    className={classNames(
                                                        active ? "bg-gray-100" : "",
                                                        "block px-4 py-2 text-sm text-gray-700"
                                                    )}
                                                >
                                                    {"Sign out"}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
