import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import {
     REACT_APP_METABASE_AIA_BREAKDOWN_AGE,
     REACT_APP_METABASE_AIA_BREAKDOWN_GENDER,
     REACT_APP_METABASE_AIA_TOP_CONCERT} from 'constants/megabase';
     import Chart from 'components/Chart';

const TopUserOutcomes = (props: any) => {
    const location = useLocation();
    const { year } = useSelector(selectAuth);
    const _year = new URLSearchParams(location.search).get('year') || year;

    const formatNumber = (num: any) => {
        // Check if the number has any decimal places
        if (num && num % 1 !== 0) {
          // If it does, format it to 2 decimal places
          return parseFloat(num.toFixed(1));
        }
        // If it's an integer, return it as is
        return num;
      }
    return (
        <div className="bg-white shadow  flex-1 mt-4">
        <h3
            style={{ color: "#4c5773" }}
            className={`text-xl px-4 leading-6 font-semibold py-5 border-grey1 mb-5`}>{`App Usage: ${_year} `}</h3>
        <div className="flex">
            <Chart height={"600px"} className={'bg-white shadow flex-1 mt-6'} question={REACT_APP_METABASE_AIA_TOP_CONCERT} />
        </div>
        <div className="flex">
            <Chart height={"600px"} className={'bg-white shadow flex-1 mt-6'} question={REACT_APP_METABASE_AIA_BREAKDOWN_GENDER} />
        </div>
        <div className="flex" >
            <Chart height={"600px"} className={'bg-white shadow flex-1 mt-6'} question={REACT_APP_METABASE_AIA_BREAKDOWN_AGE} />
        </div>

        <div className="py-6 px-8">
        <div style={{color: "#004876"}} className="text-lg">Top User Outcomes</div>
        <div className="flex flex-wrap items-center justify-start">
            {
                props?.topUserOutcomes?.map((data:any, index: number) => (
                    <div key={index} className="w-6/12 mt-4 flex items-center justify-start">
                    <div style={{color: "#009383"}} className="text-2xl w-1/5 font-bold">
                    {Math.round(data?.value)}%
                    </div>
                    <div style={{color: "#4B5563"}} className="text-sm w-4/5">
                    {data?.text}
                    </div>
                </div>
                ))
            }
        </div>
      
    </div>

    </div>

    )
}

export default TopUserOutcomes;
