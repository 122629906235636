

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import BoxTitle from "components/BoxTitle";
import { selectAuth, userSignOutAction } from "containers/SingIn/authSlice";
import { getMetabaseToken } from "containers/SingIn/authAPI";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { ten_years_ago, today } from "utils";
import { get_params } from "./param";
type ChartProps = {
    question: number | string
    className: string
    title?: string
    hFull?: boolean
    height: string
    description?: string
    is_show_label_bottom?: boolean
    label_bottom?: any
    is_year_param?: boolean
    is_month_quarter_param?: boolean
}
const Chart = (props: ChartProps) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation();

    const [token, setToken] = useState('')
    const { question, className, title, height } = props
    const [width] = useState(window.innerWidth > 986 ? (window.innerWidth - 450) / 2 : window.innerWidth - 50)
    const { month, year, department, market,
        total_user_do_dass_this_month,
        total_user_of_org_do_dass_last_month,
        total_user_of_org_do_dass_this_month, typeFilterDatetime }
        = useSelector(selectAuth);

    const _department = new URLSearchParams(location.search).get('department') || department;
    const _market = new URLSearchParams(location.search).get('market') || market;
    const is_dependent = new URLSearchParams(location.search).get('is_dependent') == 'true';
    const __month = new URLSearchParams(location.search).get('month') || month
    const __year = Number(moment(__month, "MM/YYYY").format("YYYY"))
    const is_apply_filter = new URLSearchParams(location.search).get('is_apply_filter');
    const tf_partner_id = new URLSearchParams(location.search).get('partner_id') || "default";
    const _year = new URLSearchParams(location.search).get('year') || year;
    const month_quarter = new URLSearchParams(location.search).get('month_quarter')|| "Entire year";

    let start_date: any = new URLSearchParams(location.search).get('startDate') ? moment(new URLSearchParams(location.search).get('startDate')).toDate() : ten_years_ago;
    let end_date: any = new URLSearchParams(location.search).get('endDate') ? moment(new URLSearchParams(location.search).get('endDate')).toDate() : today;

    const signOut = () => {
        history.push({
            pathname: "signin",
            search: location.search,
            state: location.state
        });
        dispatch(userSignOutAction())
    }

    const showLabelBottom = () => [30, 31, 376, 377].includes(Number(question))
    const getMetabaseTokenTask = async () => {
        let startDate = moment(start_date).format('YYYY-MM-DD');
        let endDate = moment(end_date).format('YYYY-MM-DD');
       

        let params = get_params(
            question,
            startDate,
            endDate,
            _department,
            _market,
            is_dependent,
            tf_partner_id,
            _year,
            month_quarter
        )
        const res: any = await getMetabaseToken(params)
        if (res.data && res.data.data.token) {
            setToken(res.data.data.token)
        } else {
            signOut()
        }
    }

    useEffect(() => {
        getMetabaseTokenTask()
    }, [is_apply_filter]);

    let _month = moment(__month, "MM/YYYY")

    const renderBottom = () => {
        const textMonth: { [key: string]: string } = {
            "1": "January",
            "2": "February",
            "3": "March",
            "4": "April",
            "5": "May",
            "6": "June",
            "7": "July",
            "8": "August",
            "9": "September",
            "10": "October",
            "11": "November",
            "12": "December"
        }
        let thisMonth = Number(moment(__month, "MM/YYYY").format("MM"))
        let preMonth = thisMonth - 1
        let _year = Number(moment(__month, "MM/YYYY").format("YYYY"))

        if (thisMonth == 1) {
            preMonth = 12
            _year = __year - 1
        }

        let textThisMonth = `${textMonth[`${thisMonth}`]}, ${__year}`
        let textLastMonth = `${textMonth[`${preMonth}`]}, ${_year}`

        if ([31, 377].includes(Number(question))) {
            textThisMonth = "All TFC Users"
            textLastMonth = "My Organization"
        }
        const value_1: { [key: string]: number } = {
            "30": total_user_of_org_do_dass_last_month,
            "376": total_user_of_org_do_dass_last_month,
            "31": total_user_of_org_do_dass_this_month,
            "377": total_user_of_org_do_dass_this_month

        }

        const value_2: { [key: string]: number } = {
            "30": total_user_of_org_do_dass_this_month,
            "376": total_user_of_org_do_dass_this_month,
            "31": total_user_do_dass_this_month,
            "377": total_user_do_dass_this_month
        }
        return (
            <>
                <div><span className="text-xs ml-3" style={{ color: "#A3A3A3" }}>{textLastMonth}:</span> <span className="text-xs">{value_1[`${Number(question)}`]} users completed the DASS assessment</span>  </div>
                <div><span className="text-xs ml-3" style={{ color: "#A3A3A3" }}>{textThisMonth}:</span> <span className="text-xs">{value_2[`${Number(question)}`]} users completed the DASS assessment</span>  </div>

            </>
        )
    }


    return (
        <div className={className + " relative"}>
            <div className="remove-line w-full h-3 absolute top-0 left-0 bg-white"></div>
            <div className="remove-line w-full h-14 absolute bottom-0 left-0 bg-white items-center justify-center">
                {
                    (showLabelBottom()) && (renderBottom())
                }
                {
                    props.is_show_label_bottom &&  props.label_bottom
                }
            </div>
            <div className="h-full">
                {
                    (title) && <BoxTitle title={(props.title + " " + moment(_month, "MM/YYYY").format("MM/YYYY")) || ""} />
                }
                <div className="iframe-wrapper mt-2 font-bold text-3xl text-primary" style={{ height: height }}>
                    <iframe
                        id={`myiFrame_${question}`}
                        title={`${question}`}
                        key={question}
                        className="w-full h-full"
                        src={process.env.REACT_APP_METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true/"}
                        frameBorder="0"
                        width={width}
                    ></iframe>
                </div>
            </div>

        </div>
    )
}

export default Chart
