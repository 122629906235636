

import React, { useState, useEffect } from 'react'
import Summary from '../../Sections/Summary'
import ImpactRating from '../../Sections/ImpactRating'
import SectionLabel from 'components/Section'
import SectionCenterLabel from 'components/SectionCenter'
import ListProgramsTable from '../../Sections/ListPrograms'
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux'
import RecommendationItem from 'components/ListText'
import { Link, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  ArrowSmLeftIcon

} from '@heroicons/react/outline'
import { selectAuth } from 'containers/SingIn/authSlice'
import { refreshProgramAction } from 'containers/SingIn/authAPI'

const ActivationProgramsItem = (props: any) => {
  const [webinar, setWebinar] = useState<any>()
  const { webinars } = useSelector(selectAuth)
  const params = useParams<any>();
  useEffect(() => {
    if (params?.id) {
      const currentItem = webinars?.find(item => item.id === Number(params?.id)) || {}
      setWebinar(currentItem)
    } else if (props.id) {
      const currentItem = webinars?.find(item => item.id === Number(props?.id)) || {}
      setWebinar(currentItem)
    }

  }, [params?.id]);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshProgramAction(location.search));

  }, []);
  return (
    <main>
      <div className="py-6 bg-grey">
        {!props.is_pdf_page && (
          <div className=" mx-auto px-4 sm:px-6 md:px-8">

            <Link className=" " to={"/activation-programs"}>
              <button
                type="button"
                className="flex flex-row rounded-full bg-white py-2 px-5 border border-gray"
              >
                <ArrowSmLeftIcon className="h-6 w-6 text-primary mr-3" aria-hidden="true" />
                <span> Back to summary </span>
              </button>
            </Link>
          </div>
        )}

        <div className="flex flex-col mx-auto px-4 sm:px-6 md:px-8">
          <SectionCenterLabel center title={webinar?.title || ""} />
        </div>
        <div className="flex flex-col mx-auto px-4 sm:px-6 md:px-8 mb-10 items-center justify-center">
          <span className="font-normal text-xl text-blue" >{moment(webinar?.begin_at).format("DD MMMM YYYY") || ""}</span>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div className="flex flex-wrap justify-between space-x-8  ">
            <Summary className={'bg-white shadow sm:rounded-lg flex-1'} title={'Webinar Overview'} webinar={webinar} />
          </div>
        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div className="flex flex-wrap justify-between space-x-8  ">
            <ImpactRating className={'bg-white shadow sm:rounded-lg flex-1'} title={'Impact rating'} webinar={webinar} />
          </div>

        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div className="flex flex-wrap justify-between space-x-8 flex-1 ">
            <RecommendationItem key={webinar?.id} className={'bg-white shadow sm:rounded-lg flex-1 my-10'} item={{
              title: "Top Participant Feedback Highlighted Themes",
              data: webinar?.content || []
            }} />
          </div>
        </div>
        {!props.is_pdf_page && (
          <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <SectionLabel title="List of Programs " />
            <div className="flex flex-wrap justify-between space-x-8 bg-white shadow sm:rounded-lg flex-1 ">
              <ListProgramsTable data={webinars} />
            </div>
          </div>
        )}

      </div>
    </main>
  )
}

export default ActivationProgramsItem