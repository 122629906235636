import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';

import { getMetabaseData } from 'containers/SingIn/authAPI';
import { REACT_APP_METABASE_AIA_NUMBER_COMPLETED_DASS, REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED, } from 'constants/megabase';
import SectionLabel from 'components/Section';
import ChartPdf from 'containers/ExportPdf/ChartPdf';

const AiaHealthOutcomesChartPdf = (props: any) => {
    const location = useLocation();
    const { year } = useSelector(selectAuth);
    const _year = new URLSearchParams(location.search).get('year') || year;

    const decode_and_parse: any = (key: string, defaultValue: string) => {
        const value = new URLSearchParams(location.search).get(key) || defaultValue;
        return JSON.parse(decodeURIComponent(value))
    }

    const numberCompletedDass = decode_and_parse("numberCompletedDass", "[]");
  
    return (
        <main id="myiFrame" className="px-6">

        <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">

            <SectionLabel key={'SectionLabel-3'} title="Health Outcomes" />
            <div className="mx-auto mb-4 items-center justify-center">
                <span className="font-normal text-xl text-blue" >*Improvement scores were calculated based on percentage of respondents showing improvements in DASS-21 scores over the period of coverage.</span>
            </div>
            <div style={{ color: "#004876" }} className='font-lg font-semibold'> <span className=''>{`Number of Respondents who Completed >2 DASS Assessments FY${_year}: ${numberCompletedDass}`}</span> </div>
            <div className="flex flex-wrap justify-between space-x-8  mb-10">

                <ChartPdf
                    height={"75vh"}
                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                    question={REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED}
                />
            </div>
        </div>
        </main>
    )
}

export default AiaHealthOutcomesChartPdf;
