import React from "react";

import {
    useHistory, useLocation
} from "react-router-dom";
import queryString from "query-string";
import { selectAuth } from "containers/SingIn/authSlice";
import { useSelector } from "react-redux";
import { add_params_and_push } from "utils";
import FilterButton from "./filter_button";
import CloseButton from "./close_button";
import ApplyButton from "./apply_button";
import DependentButton from "../Topbar/dependent_button";
import ReferralUserButton from "../Topbar/referral_user_button";
import DepartmentButton from "../Topbar/department_button";
import MarketButton from "../Topbar/market_button";
import DatetimeButton from "../Topbar/datetime_button";

const FilterBar = (props: any) => {
    const location = useLocation();
    const history = useHistory()
    const { 
        isDependent,
        setIsDependent, 
        isReferralUser, 
        setIsReferralUser, 
        departments,  
        onChangeDepartment,
        markets,
        onChangeMarket,
        handleClickCalendar,
        applyDateFilter,
        handleCalendarClose,
        handleCalendarOpen,
        openDateMenu,
        onChangeTypeFilterDatetime,
        applyYearFilter
    } = props;
    const {
        org,
    } = useSelector(selectAuth);

    const filterbarOpen = new URLSearchParams(location.search).get('filter_bar_open') == "true"
    const filterbarOpenNow = new URLSearchParams(location.search).get('filter_bar_open_now') == "true"

    const setFilterbarOpen = (value: boolean) => {
        add_params_and_push({ filter_bar_open: value, filter_bar_open_now: value }, location, queryString, history)
    }

    const setFilterbarClose = (value: boolean) => {
        add_params_and_push({ filter_bar_open_now: value }, location, queryString, history)

        setTimeout(() => {
            add_params_and_push({ filter_bar_open: value, filter_bar_open_now: value }, location, queryString, history)
        }, 300);
    }

    return (
        <>
            <FilterButton setFilterbarOpen={setFilterbarOpen} />

            <div 
            className={filterbarOpen ? "" : "hidden"}
            >
                {/*filterbarOpenNow just custom the animation close of the side */}
                <div 
                id={`${filterbarOpenNow ? "loadingOverlayFilter" : "loadingOverlayFilterClose"}`}
                onClick={() => setFilterbarClose(false)}></div>
                <div id={`${filterbarOpenNow ? "side-bar-open" : "side-bar-close"}`} className="inset-y-0 right-0 flex w-72 flex-col fixed z-10" >
                    
                        <CloseButton setFilterbarClose={setFilterbarClose} />
                    <div className="flex flex-col flex-grow bg-white overflow-y-auto" >
                        <div className="text-lg	font-semibold pl-4 pt-4" style={{height: "60px", color: "#004876"}}>
                            <span>Filter</span>
                        </div>

                        <div className="pl-4" style={{height: "calc(100% - 120px)"}}>

                       
                        <DependentButton isDependent={isDependent} setIsDependent={setIsDependent} />
                        <ReferralUserButton isReferralUser={isReferralUser} setIsReferralUser={setIsReferralUser} />
                        <DatetimeButton
                            handleClickCalendar={handleClickCalendar}
                            applyDateFilter={applyDateFilter}
                            handleCalendarClose={handleCalendarClose}
                            handleCalendarOpen={handleCalendarOpen}
                            openDateMenu={openDateMenu}
                            onChangeTypeFilterDatetime={onChangeTypeFilterDatetime}
                            applyYearFilter={applyYearFilter}
                        />
                        <DepartmentButton departments={departments} onChangeDepartment={onChangeDepartment} />
                        <MarketButton markets={markets} onChangeMarket={onChangeMarket} />

                        </div>
                        
                        <ApplyButton setFilterbarClose={setFilterbarClose} apply={props?.applyFilter} />

                    </div>
                </div>



            </div>
        </>

    )
}
export default FilterBar