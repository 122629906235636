import TypeEmail from "./Step/TypeEmail";
import TypeOTP from "./Step/TypeOTP";
import Logo from "assets/images/logo.png";
import LogoRight from "assets/images/screenshot_destop.png"
import { useSelector } from "react-redux";
import { selectAuth } from "./authSlice";
import "./signin.css"
const SignIn = (props: any) => {
  const { useremail } = useSelector(selectAuth);

  return (
    <>
      <div className="min-h-full flex">
        <div className="left-column flex-1 px-20 py-20">
          <div className="form mx-auto w-full max-w-sm lg:w-96 mt-16">
            <div className="flex items-center justify-center logo-login">
              <img className="h-24 w-auto my-10" src={Logo} alt="Workflow" />
            </div>
            <div className="">
              <div className="flex flex-col items-center justify-center">
                <div className="text-3xl">
                  <h1 className="font-bold text-primary text-center">
                    Welcome to
                    <span className="text-blue">
                      &nbsp;ThoughtFull Insights
                    </span>
                  </h1>
                </div>
              </div>
              <div className="mt-6">
                <div className="space-y-6">
                  {!useremail ? <TypeEmail /> : <TypeOTP {...props} />}
                  <div className="flex items-center justify-center login-service-area">
                    <div className="text-sm">
                      <a
                        href="https://www.thoughtfull.world/clients-terms-of-use"
                        target="_blank"
                        className="font-normal text-primary hover:text-indigo-500"
                      >
                        Terms of Use
                      </a>
                    </div>
                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-normal text-primary hover:text-indigo-500"
                      >
                        &nbsp;|&nbsp;
                      </a>
                    </div>
                    <div className="text-sm">
                      <a
                        href="https://www.thoughtfull.world/privacy-policy"
                        target="_blank"
                        className="font-normal text-primary hover:text-indigo-500"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="right-column flex-2 h-screen hidden lg:block relative bg-login-bg bg-no-repeat bg-cover bg-center ">
          <div className="h-full flex flex-col items-center justify-center">
            <div className="top-section text-xl px-28 mt-20">
              <h1
                style={{ fontFamily: "Cambria" }}
                className="py-5 px-10 font-bold text-white text-center font-size-32 leading-tight">
                <div
                  style={{ fontFamily: "Cambria" }}>
                  Everything about
                </div>
                your organization’s employee wellbeing
                program in one place
              </h1>
              <div className="list-content flex mt-8 justify-center items-center">
                <ul className="text-white font-size-14 list-disc">
                  <li>
                    Access real-time insights to make data-driven decisions for your peoples’ wellbeing, always, fuss-free.
                  </li>
                  <li>
                    Your one-stop source of information for your organization’s employee well-being program.
                  </li>
                  <li>
                    Access real-time employee well-being insights, get updated with the latest progress.
                  </li>
                  <li>
                    Be empowered with data-driven recommendations.
                  </li>
                </ul>
              </div>


            </div>
            <div className="mt-14 photo-screen">
              <img className="" src={LogoRight} alt="" />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
