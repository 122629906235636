const DassScoreChart = (props: any) => {
    const {
        department,
        normal,
        neutral,
        severe
    } = props.data || {};
    return (
        <div>
            <div style={{color: "#009383"}} className="mt-2 underline text-sm">{department}</div>
            <div className="flex mt-2 text-xs">
                <div className="text-right" style={{ width: `${normal}%` }}>
                    <div className="opacity-0">Normal</div>
                    <div className="opacity-0" >Normal</div>


                    {normal > 0 &&
                        (
                            <>
                                <div className="text-xs" style={{ backgroundColor: "#94d3bf" }}>{normal}%</div>
                                <div style={{color: "#59817D"}} className="text-xs">Normal</div>
                            </>
                        )
                    }
                </div>
                <div className="text-right" style={{ width: `${neutral}%` }}>
                    {neutral > 0 &&
                        (
                            <>
                                <div style={{color: "#59817D"}} className="text-xs">Mild</div>
                                <div style={{color: "#59817D"}} className="text-xs">Moderate</div>
                                <div className="text-xs" style={{ backgroundColor: "#ffff99" }}>{neutral}%</div>
                            </>
                        )

                    }

                    <div className="opacity-0" >Normal</div>

                </div>
                <div className="text-right" style={{ width: `${severe}%` }}>
                    <div className="opacity-0" >Normal</div>
                    <div className="opacity-0" >Normal</div>

                    {
                        severe > 0 && (
                            <>
                                <div className="text-xs" style={{ backgroundColor: "#da9199" }}>{severe}%</div>
                                <div style={{color: "#59817D"}} className="text-xs">Severe</div>
                                <div  style={{color: "#59817D"}} className="text-xs">Extremely Severe</div>
                            </>
                        )
                    }


                </div>
            </div>
        </div>
    )
}

export default DassScoreChart

