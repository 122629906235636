import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import ToggleBtn from "components/Toggle";
import { useLocation } from "react-router-dom";


const DependentButton = (props: any) => {
  const location = useLocation();
  const { org } = useSelector(selectAuth);

  const { setIsDependent } = props;
  const isDependent = new URLSearchParams(location.search).get('is_dependent') == "true"

  return (
    <>
      {
        org?.is_aia_cs && org?.count_dependent_user > 0 && !window.location.href.includes("/tf-aia-cs") && (
          <div className='flex items-center'>
            <div
              style={{ marginBottom: "0.5rem" }}
              className="mr-2 mt-2">
              <ToggleBtn enabled={isDependent} setEnabled={setIsDependent} />

            </div>
            <div
              style={{ marginBottom: "0.5rem" }}
              className="ml-4 font-bold max-w-xl text-lg text-primary items-center">
              Dependents
            </div>
          </div>

        )
      }
    </>
  )
}

export default DependentButton;