
import { useLocation } from "react-router-dom";


const MarketButton = (props: any) => {

    const { markets, onChangeMarket } = props;
    const { state: stateRouter } = useLocation<any>();
    const location = useLocation();
    const market = new URLSearchParams(location.search).get('market') || ""
    return (
        <>
         {
                !!stateRouter?.isShowBtnMartket && markets.length > 0 && (
                  <div style={{ color: "#374151" }} className='text-sm mt-4'>
                  <div>Markets</div>
                  <div 
                  style={{marginBottom: "0.5rem"}}
                  className=" cursor-pointer mt-2 mr-1 border-2 border-grey1 p-1 rounded-md">
                    <select
                      id="location"
                      onChange={onChangeMarket}
                      name="location"
                      className="cursor-pointer w-full block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      value={market}
                      style={{color: `${market == "" ? "#9CA3AF" : ""}`}}
                    >
                      {markets.map((item: any, index: number) => {
                        return (
                          <option
                            key={index}
                            value={item.value}
                          >
                            {item.item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  </div>
                )
              }
        </>
    )
}

export default MarketButton;