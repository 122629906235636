import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED,REACT_APP_METABASE_AIA_AVG_DASS_SCORES } from 'constants/megabase';
import SectionLabel from 'components/Section';
import Chart from 'components/Chart';
import InterpretationDassScoresTable from './interpretation_dass_scores_table';

const Overview = (props: any) => {
    const location = useLocation();
    const { year } = useSelector(selectAuth);
    const _year = new URLSearchParams(location.search).get('year') || year;
   
    return (
        <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">

            <SectionLabel key={'SectionLabel-3'} title="Health Outcomes" />
            {/* <div className="mx-auto mb-4 items-center justify-center">
                <span className="font-normal text-xl text-blue" >*Improvement scores were calculated based on percentage of respondents showing improvements in DASS-21 scores over the period of coverage.</span>
            </div> */}
            <div style={{ color: "#004876" }} className='font-lg font-semibold'> <span className=''>{`Number of Respondents who Completed >2 DASS Assessments FY${_year}: ${props?.numberCompletedDass}`}</span> </div>
            <div className="flex flex-wrap justify-between space-x-8  mb-10">

                <Chart
                    height={"600px"}
                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                    question={REACT_APP_METABASE_AIA_PERCENT_OF_USERS_IMPROVED}
                    is_show_label_bottom={false}
                    label_bottom={<div style={{ color: "#004876" }} className='font-lg font-semibold'> <span className='ml-6'>{`Number of Respondents who Completed >2 DASS Assessments FY${_year}: ${props?.numberCompletedDass}`}</span> </div>}
                    is_year_param={true}
                    is_month_quarter_param={true}
                />
            </div>

            <div className="flex flex-wrap justify-between space-x-8">

                <Chart
                    height={"600px"}
                    className={'bg-white shadow sm:rounded-lg flex-1'}
                    question={REACT_APP_METABASE_AIA_AVG_DASS_SCORES}
                    is_show_label_bottom={false}
                    label_bottom={<div style={{ color: "#004876" }} className='font-lg font-semibold'> <span className='ml-6'>{`Number of Respondents who Completed >2 DASS Assessments FY${_year}: ${props?.numberCompletedDass}`}</span> </div>}
                    is_year_param={true}
                    is_month_quarter_param={true}
                />
            </div>
            <div className="flex flex-wrap justify-between space-x-8  mb-10">
                <InterpretationDassScoresTable />
            </div>
        </div>
    )
}

export default Overview;
