

import React, { useState, useEffect } from 'react'
import Summary from 'containers/ActivationPrograms/Sections/Summary'
import ImpactRating from 'containers/ExportPdf/ActivationProgramsPdf/ImpactRating'
import SectionLabel from 'components/Section'
import SectionCenterLabel from 'components/SectionCenter'
import ListProgramsTable from 'containers/ActivationPrograms/Sections/ListPrograms'
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux'
import RecommendationItem from 'components/ListText'
import { Link, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  ArrowSmLeftIcon

} from '@heroicons/react/outline'
import { selectAuth } from 'containers/SingIn/authSlice'
import { refreshProgramAction } from 'containers/SingIn/authAPI'

const ImpactRatingPdf = (props: any) => {
  const location = useLocation();

  const decode_and_parse: any = (key: string, defaultValue: string) => {
    const value = new URLSearchParams(location.search).get(key) || defaultValue;
    return JSON.parse(decodeURIComponent(value))
  }
  const webinar = decode_and_parse("webinar", "[]");

  return (
    // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
    <main id="myiFrame">
      <div className="py-6 bg-grey">
        <div style={{width: "850px"}} className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div  className="flex flex-wrap justify-between space-x-8  ">
            <ImpactRating className={'bg-white shadow sm:rounded-lg flex-1'} title={'Impact rating'} webinar={webinar} />
          </div>

        </div>
        <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
          <div className="flex flex-wrap justify-between space-x-8 flex-1 ">
            <RecommendationItem key={webinar?.id} className={'bg-white shadow sm:rounded-lg flex-1 my-10'} item={{
              title: "Top Participant Feedback Highlighted Themes",
              data: webinar?.content || []
            }} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default ImpactRatingPdf