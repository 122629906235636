import React from 'react'
import { Container, Button, Link } from 'react-floating-action-button';
import {
    DownloadIcon
  } from '@heroicons/react/outline';

 const FloatingButton = (props: any) => {
    return (
        <Container class="floating-button-container">
            <Button
                class="floating-button"
                tooltip={props.tooltip || ""}
                onClick={() => props.handleAction()} 
                style={{backgroudColor: "#009383 !important"}}
                >
                     <DownloadIcon color="#fafafa" width="25" height="25" />
                 </Button>   
        </Container>
    )
}


export default FloatingButton;