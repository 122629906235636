
const InterpretationDassScoresTable = (props: any) => {
    const data = [
        {
            meaning: "Normal",
            depression: "0 - 9",
            anxiety: "0 - 7",
            stress: "0 - 14"
        },
        {
            meaning: "Mild",
            depression: "10 - 13",
            anxiety: "8 - 9",
            stress: "15 - 18"
        },
        {
            meaning: "Moderate",
            depression: "14 - 20",
            anxiety: "10 - 14",
            stress: "19 - 25"
        },
        {
            meaning: "Severe",
            depression: "21 - 27",
            anxiety: "15 - 19",
            stress: "26 - 33"
        },
        {
            meaning: "Extremely Severe",
            depression: "28",
            anxiety: "20",
            stress: "34"
        },
    ]
    return (
       // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <div 
        id="myiFrame"
        className="w-full mx-auto bg-white">
            <div
                className="font-semibold bg-white p-4"
                style={{ color: "#6B7280" }}>Interpretation of DASS Scores</div>
            <div className="flex flex-wrap justify-between space-x-8  px-4">
                <div
                    style={{ borderColor: "#E5E7EB" }}
                    className="w-full flex flex-col  border-solid border rounded-md">
                    <div className="w-full -my-2 overflow-x-auto">
                        <div className="w-full py-2 align-middle inline-block ">
                            <div className="w-full shadow">
                                <table
                                    // style={{ minWidth: "1550px" }}
                                    className="divide-y divide-grey1 w-full">
                                    <thead className="bg-white">
                                        <tr>
                                            <th
                                                style={{ color: "#4B5563" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                MEANING
                                            </th>
                                            <th
                                                style={{ color: "#4B5563" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                DEPRESSION
                                            </th>
                                            <th
                                                style={{ color: "#4B5563" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                ANXIETY
                                            </th>
                                            <th
                                                style={{ color: "#4B5563" }}
                                                scope="col"
                                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                STRESS
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((item: any, personIdx: number) => (
                                            <tr
                                                // onClick={() => handleActiPrItem(item.id)}
                                                key={item.id}
                                                // style={{ cursor: "pointer" }}
                                                className={`${personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'} hover:bg-[#e6fcf9]`}>

                                                <td
                                                    style={{ color: "#4B5563" }}
                                                    className="px-6 py-4   text-sm font-medium text-gray-900">
                                                    {item.meaning}</td>
                                                <td
                                                    style={{ color: "#4B5563" }}
                                                    className="px-6 py-4   text-sm text-gray-500 ">{item.depression}</td>
                                                <td
                                                    style={{ color: "#4B5563" }}
                                                    className="px-6 py-4   text-sm text-gray-500">{item.anxiety}</td>
                                                <td
                                                    style={{ color: "#4B5563" }}
                                                    className="px-6 py-4   text-sm text-gray-500 text-center">{item.stress}</td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div
                    style={{ color: "#6B7280" }}
                    className="font-semibold p-4">
                    Understanding DASS Insights
                </div>
                <div
                    style={{ color: "#6B7280" }}
                    className="text-sm	font-normal px-4 leading-6	mt-1">
                    1. DASS-21 is Depression, Anxiety and Stress Scale. It’s a self report measure that consists of 21 items and is meant to detect symptoms of Depression, Anxiety and stress as indicated by the user.
                </div>

                <div
                    style={{ color: "#6B7280" }}
                    className="text-sm	font-normal px-4 leading-6	mt-1">
                    2. The scale is not meant to be used for diagnosis. It is a screening tool used to make the user and psychologist aware of the e xperiences of symptoms. It does not substitute a full diagnosis. Since the DASS measures self reported changes in symptoms for the last two weeks since the time of response, it is a dynamic number that varies based on the experience of symptoms as self-reported by the user.
                </div>
                <div
                    style={{ color: "#6B7280" }}
                    className="text-sm	font-normal px-4 leading-6	pb-4 mt-1">
                    3. Improvement means change a lowering of DASS 21 scores within a given interval. This is measured by looking at the first and last scores within that interval. The percentage reflects the number of users with reduced DASS scores within the given period. Example: if improvement is 60%, it means 60% of users who completed the DASS-21 at least twice within that period experienced an improvement at least once (DASS scores lowered)                </div>
            </div>
        </div >

    )
}

export default InterpretationDassScoresTable;

