const FilterButton = (props: any) => {

    return (
        <div
        className='mb-1 mr-2 cursor-pointer'
        // style={{width: "37px", height: "37px"}}
        onClick={() => props?.setFilterbarOpen(true)}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#EAFAF7" />
                <path d="M16.0002 10.9999V9.33325M16.0002 10.9999C15.0797 10.9999 14.3335 11.7461 14.3335 12.6666C14.3335 13.5871 15.0797 14.3333 16.0002 14.3333M16.0002 10.9999C16.9206 10.9999 17.6668 11.7461 17.6668 12.6666C17.6668 13.5871 16.9206 14.3333 16.0002 14.3333M11.0002 20.9999C11.9206 20.9999 12.6668 20.2537 12.6668 19.3333C12.6668 18.4128 11.9206 17.6666 11.0002 17.6666M11.0002 20.9999C10.0797 20.9999 9.3335 20.2537 9.3335 19.3333C9.3335 18.4128 10.0797 17.6666 11.0002 17.6666M11.0002 20.9999V22.6666M11.0002 17.6666V9.33325M16.0002 14.3333V22.6666M21.0002 20.9999C21.9206 20.9999 22.6668 20.2537 22.6668 19.3333C22.6668 18.4128 21.9206 17.6666 21.0002 17.6666M21.0002 20.9999C20.0797 20.9999 19.3335 20.2537 19.3335 19.3333C19.3335 18.4128 20.0797 17.6666 21.0002 17.6666M21.0002 20.9999V22.6666M21.0002 17.6666V9.33325" stroke="#009383" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>

    )
}
export default FilterButton