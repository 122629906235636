import { ten_years_ago, today } from './../../utils/index';
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import queryString from "query-string";
import axios from "store/axios";
import {
  saveEmail,
  saveMessEmail,
  saveMessOTP,
  signoutUserSuccess,
  loadUserDataSuccess,
  loadProgramSuccess,
} from "./authSlice";
export const getProgram = async (query: string) => {
  try {
    const res = await axios.get("/hr/v1/programs/" + query);
    return res;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

//API send Email
export const sendEmailAction = createAsyncThunk(
  "auth/sendEmail",
  async (useremail: string, { dispatch }) => {
    try {
      const response = await sendEmail(useremail);
      if (response.data?.data) {
        dispatch(saveEmail(useremail));
      } else {
        dispatch(saveMessEmail(response.message));
      }
      return response;
    } catch (error) {
      console.log("error :>> ", error);
    }
  }
);
export const sendEmail = async (email: string) => {
  try {
    const res = await axios.post("/hr/v1/users/email_exists", { email });
    return res;
  } catch (error: any) {
    //console.log("----error.response---",error.response.data)
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

export const doExportPdf = async (data: any) => {
  try {
    const res = await axios.post("/hr/v1/export_pdf", { data });
    return res;
  } catch (error: any) {
    //console.log("----error.response---",error.response.data)
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

export const doUploadDataToS3 = async (data: any) => {
  try {
    const res = await axios.post("/hr/v1/upload_data_to_s3", { data });
    return res;
  } catch (error: any) {
    //console.log("----error.response---",error.response.data)
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

//API send OTP
export const sendOTPAction = createAsyncThunk(
  "auth/sendOTP",
  async (
    params: { otp: string; useremail: string; history: any },
    { dispatch, getState }
  ) => {
    const { auth } = getState() as { auth: any };
    try {
      const { otp, useremail, history } = params || {};
      const res = await sendOTP(otp, useremail);
      if (res && res.data) {
        const token = res.data.data.token;
        localStorage.setItem("token", token);
        dispatch(fetchUserData(history, auth.programId));
      } else {
        dispatch(
          saveMessOTP(
            res?.errors[0] ||
            "Your OTP is invalid or expired, please try again!"
          )
        );
      }
      return res;
    } catch (error) {
      console.log("error :>> ", error);
    }
  }
);
export const sendOTP = async (otp: string, email: string) => {
  try {
    const res = await axios.post("/hr/v1/users/otp", { otp, email });
    return res;
  } catch (error: any) {
    //console.log("----error.response---",error.response.data)
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

//API fetch user data
export const fetchUserData = (history: any, programId: string) => {
  return async (dispatch: any, getState: any) => {
    const res = await getProgram(programId);
    if (res && res.data) {
      const datas = res.data.data;
      const recommendations = datas.recommendations.map((item: any) => {
        const newItem = {
          title: Object.keys(item)[0],
          data: item[Object.keys(item)[0]],
        };
        return newItem;
      });
      const userData = { ...datas, recommendations };
      dispatch(loadUserDataSuccess(userData));
      
      if (
        history.location.pathname === "/signin" ||
        history.location.pathname === "/token"
      ) {
        console.log('history.location.state', history.location)
        let is_aia_cs: any = new URLSearchParams(history.location.search).get('is_aia_cs') == "true" || userData?.user?.role == "AIACS"
        if (is_aia_cs) {
          history.push({
            pathname: "tf-aia-cs",
            search: history.location.search,
            state: { isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
          });
        } else {
          history.push({
            pathname: "overview",
            search: history.location.search,
            state: { isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
          });
        }
       
      }
    } else {
      localStorage.removeItem("token");
      dispatch(signoutUserSuccess);
    }
  };
};

//API fetch list program

export const fetchListProgramsAction = createAsyncThunk(
  "auth/fetchListPrograms",
  async (_, { dispatch }) => {
    try {
      const response = await getPrograms();
      if (response && response.data) {
        dispatch(loadProgramSuccess(response.data.data.programs));
      }
      return response;
    } catch (error) {
      console.log("error :>> ", error);
    }
  }
);

export const getPrograms = async () => {
  try {
    const res = await axios.get("/hr/v1/programs/");
    return res;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

//API resfresh program
export const refreshProgramAction = createAsyncThunk(
  "auth/refreshProgram",
  async (data: any, { dispatch, getState }) => {
    const { auth } = getState() as { auth: any };
    let { programId } = auth || {};
    const params: any = queryString.parse(data);
    let { month, year, department, market, is_dependent, startDate, endDate, typeFilterDatetime, isReferralUser, partner_id } =
      params || {};

    let start_date = startDate ? startDate : ten_years_ago;

    let end_date = endDate ? endDate : today;

    let tf_partner_id = Boolean(isReferralUser) ? partner_id : "default";

    let query =
      "" +
      programId +
      "?start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&department=" +
      (department || "") +
      "&is_dependent=" +
      Boolean(is_dependent) +
      "&is_referral_user=" +
      Boolean(isReferralUser) +
      "&market=" +
      (market || "") + "&partner_id=" + tf_partner_id;
    console.log("query", query);
    const res = await getProgram(query);
    if (res && res.data) {
      const datas = res.data.data;
      const recommendations = datas.recommendations.map((item: any) => {
        const newItem = {
          title: Object.keys(item)[0],
          data: item[Object.keys(item)[0]],
        };
        return newItem;
      });
      const userData = { ...datas, recommendations };
      dispatch(loadUserDataSuccess(userData));
    }
  }
);

//get token metabase
export const getMetabaseToken = async (data: {
  end_date?: string;
  question_id: number | string;
  start_date: string;
  department?: string;
  market?: string;
  is_dependent?: boolean;
  partner_id?: string;
}) => {
  try {
    const res = await axios.post("/hr/v1/metabase/", data);
    return res;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

//get token metabase
export const getMetabaseData =  async (data: any)=> {
  try {
    const res = await axios.post("/hr/v1/metabase/data", data);
    return res;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};

//API gentoken
export const genTokenAction = (
  token: string,
  history: any,
  programId: string
) => {
  return async (dispatch: any) => {
    const res = await getToken(token);
    if (res && res.data) {
      const token = res.data.data.token;
      localStorage.setItem("token", token);
      dispatch(fetchUserData(history, programId));
    }
  };
};
export const getToken = async (uid: string) => {
  try {
    // const res = await axios.get("/hr/v1/users/"+uid+"/admin_token")
    const res = await axios.get(`/hr/v1/users/${uid}/admin_token`);
    return res;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return {
      error: "There is an exception error !",
    };
  }
};
