import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { rootReducer } from './reducer';

const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory();

export const store = configureStore({
  reducer : rootReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


