import { 
     REACT_APP_METABASE_AIA_TOP_CONCERT} from 'constants/megabase';
import { useLocation } from 'react-router-dom';
import ChartPdf from 'containers/ExportPdf/ChartPdf';

const AiaTopConcernsChart = (props: any) => {
    const location = useLocation();

    const _year = new URLSearchParams(location.search).get('year');
    
    return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <main id="myiFrame">
        <div className="bg-white shadow  flex-1 mt-4">
        <h3
            style={{ color: "#4c5773" }}
            className={`text-xl px-4 leading-6 font-semibold py-5 border-grey1 mb-5`}>{`App Usage: ${_year} `}</h3>
        <div className="flex">
            <ChartPdf is_not_full={true} height={"80vh"} className={'bg-white shadow flex-1 mt-6'} question={REACT_APP_METABASE_AIA_TOP_CONCERT} />
        </div>

        </div>
        </main>

    )
}

export default AiaTopConcernsChart;
