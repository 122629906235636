import { useState } from 'react'
import { Switch } from '@headlessui/react'

interface ToggleProps {
    enabled: boolean;
    setEnabled: any;
}
export default function ToggleBtn(props: ToggleProps) {
    const { enabled, setEnabled } = props;

    return (
        <div className="">
            <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${enabled ? 'bg-teal-700' : 'bg-gray'}
          relative inline-flex h-[28px] w-[67px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[23px] w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </div>
    )
}
