
import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useHistory, useLocation } from "react-router-dom";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useState } from 'react';
import queryString from "query-string";
import { add_params_and_push, ten_years_ago, today } from 'utils';

const YearPicker = (props: any) => {
    const history = useHistory()
    const location = useLocation();

    const { handleClickCalendar, applyDateFilter, handleCalendarClose, handleCalendarOpen, openDateMenu, onChangeTypeFilterDatetime, applyYearFilter } = props;
    const { typeFilterDatetime, month, year } = useSelector(selectAuth);
    const { state: stateRouter } = useLocation<any>();

    const defaultStartDate = new URLSearchParams(location.search).get('startDate') ? moment(new URLSearchParams(location.search).get('startDate')).toDate() : null;
    const defaultEndDate = new URLSearchParams(location.search).get('endDate') ? moment(new URLSearchParams(location.search).get('endDate')).toDate() : null;

    const [dateRange, setDateRange] = useState([defaultStartDate, defaultEndDate]);
    const [startDate, endDate] = dateRange;

    const _month = new URLSearchParams(location.search).get('month') || month
    const _year = new URLSearchParams(location.search).get('year') ? Number(new URLSearchParams(location.search).get('year')) : year

    const handleSetDateRange = (update: any) => {
        const startDate = update[0]
        const endDate = update[1]
        setDateRange(update)
        if (startDate && endDate) {

            add_params_and_push({
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
            }, location, queryString, history)
        }

        if (!startDate && !endDate) {
            add_params_and_push({
                startDate: null,
                endDate: null
            }, location, queryString, history)
            // remove_params_and_push()
        }
    }
    return (
        <div
            style={{ marginBottom: "0.5rem" }}
            className="mr-1 border-2 border-grey1 p-1 rounded-md sm:text-sm">
            <DatePicker
                id="year-date-picker"
                selected={new Date().setFullYear(_year)}
                onChange={applyYearFilter}
                showYearPicker
                showFullYearPicker
                showFourColumnYearPicker
                dateFormat="yyyy"
                yearItemNumber={10}
            />
        </div>

    )
}

export default YearPicker;