import moment from "moment";
import queryString from "query-string";
import { getToken, ten_years_ago, today } from "utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "localhost:3000";

const _stringify: any = (value: any) => {
  return encodeURIComponent(JSON.stringify(value));
};
const get_pages = (state: any) => {
  let {
    program,
    org,
    webinar_summary,
    app,
    departmental_health_overview,
    is_dependent,
    month,
    is_fwd_org,
    year,
    total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month,
    webinars,
    recommendations,
    top_learn,
    org_department_users,
    org_market_users,
    countries,
    market,
    department,
    start_date,
    end_date,
    video_therapy_session_count
  } = state;

  start_date = start_date ? start_date : ten_years_ago;

  end_date = end_date ? end_date : today;
  // 1
  const path_1 = `${BASE_URL}/overview-pdf`;
  const query_1 = {
    program: _stringify(program),
    org: _stringify(org),
    webinar_summary: _stringify(webinar_summary),
    app: _stringify(app),
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };
  const url_1 = queryString.stringifyUrl({ url: path_1, query: query_1 });

  // 2
  const path_2 = `${BASE_URL}/department-health-pdf`;

  const query_2 = {
    departmental_health_overview: _stringify(departmental_health_overview),
    program: _stringify(program),
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date,
    video_therapy_session_count: video_therapy_session_count
  };

  console.log('query_2', query_2)
  const url_2 = queryString.stringifyUrl({ url: path_2, query: query_2 });

  // 3
  const path_3 = `${BASE_URL}/fwd-metric-chart-pdf`;

  const query_3 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    is_fwd_org: is_fwd_org,
    startDate: start_date,
    endDate: end_date
  };
  const url_3 = queryString.stringifyUrl({ url: path_3, query: query_3 });

  // 4
  const path_4 = `${BASE_URL}/user-acqisition-chart`;

  const query_4 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_4 = queryString.stringifyUrl({ url: path_4, query: query_4 });

  //  5
  const path_5 = `${BASE_URL}/country-break-down-chart`;

  const query_5 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };
  const url_5 = queryString.stringifyUrl({ url: path_5, query: query_5 });

  //  6
  const path_6 = `${BASE_URL}/top-concerns-chart`;

  const query_6 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };
  const url_6 = queryString.stringifyUrl({ url: path_6, query: query_6 });

  //  7
  const path_7 = `${BASE_URL}/gender-break-down-chart`;

  const query_7 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };
  const url_7 = queryString.stringifyUrl({ url: path_7, query: query_7 });

  //  8
  const path_8 = `${BASE_URL}/age-break-down-chart`;

  const query_8 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };
  const url_8 = queryString.stringifyUrl({ url: path_8, query: query_8 });

  //  9
  const path_9 = `${BASE_URL}/app-usage-pdf`;

  const query_9 = {
    app: _stringify(app),
    startDate: start_date,
    endDate: end_date
  };
  const url_9 = queryString.stringifyUrl({ url: path_9, query: query_9 });

  //  10
  const path_10 = `${BASE_URL}/learning-pack-pdf`;

  const query_10 = {
    app: _stringify(app),
    top_learn: _stringify(top_learn),
    startDate: start_date,
    endDate: end_date
  };
  const url_10 = queryString.stringifyUrl({ url: path_10, query: query_10 });

  //   11
  const path_11 = `${BASE_URL}/mood-break-down-chart`;

  const query_11 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_11 = queryString.stringifyUrl({ url: path_11, query: query_11 });

  //   12
  const path_12 = `${BASE_URL}/frequency-of-positive-moods-based-on-reasons-chart`;

  const query_12 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_12 = queryString.stringifyUrl({ url: path_12, query: query_12 });

  //   13
  const path_13 = `${BASE_URL}/frequency-of-negative-moods-based-on-reasons-chart`;

  const query_13 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_13 = queryString.stringifyUrl({ url: path_13, query: query_13 });

  //   14
  const path_14 = `${BASE_URL}/mood-frequencies-chart`;

  const query_14 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_14 = queryString.stringifyUrl({ url: path_14, query: query_14 });

  //   15
  const path_15 = `${BASE_URL}/frequency-of-moods-based-on-days-chart`;

  const query_15 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_15 = queryString.stringifyUrl({ url: path_15, query: query_15 });

  //   16
  const path_16 = `${BASE_URL}/reasons-ssociated-with-the-top-moods-chart`;

  const query_16 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_16 = queryString.stringifyUrl({ url: path_16, query: query_16 });

  //   17
  const path_17 = `${BASE_URL}/user-outcome-break-down-chart`;

  const query_17 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_17 = queryString.stringifyUrl({ url: path_17, query: query_17 });

  //   18
  const path_18 = `${BASE_URL}/feedback-on-tf-profs-chart`;

  const query_18 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_18 = queryString.stringifyUrl({ url: path_18, query: query_18 });

  //   19
  const path_19 = `${BASE_URL}/dass-score-of-my-org-chart`;

  const query_19 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_19 = queryString.stringifyUrl({ url: path_19, query: query_19 });

  //   20
  const path_20 = `${BASE_URL}/dass-score-of-this-month-chart`;
  
  const query_20 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_20 = queryString.stringifyUrl({ url: path_20, query: query_20 });

  //   21
  const path_21 = `${BASE_URL}/monthy-dass-score-of-my-org-chart`;

  const query_21 = {
    access_token: getToken(),
    department: department,
    market: market,
    month: month,
    year: year,
    total_user_do_dass_this_month: total_user_do_dass_this_month,
    total_user_of_org_do_dass_last_month: total_user_of_org_do_dass_last_month,
    total_user_of_org_do_dass_this_month: total_user_of_org_do_dass_this_month,
    is_dependent: is_dependent,
    startDate: start_date,
    endDate: end_date
  };

  const url_21 = queryString.stringifyUrl({ url: path_21, query: query_21 });

  //   22
  const path_22 = `${BASE_URL}/clinical-insights-pdf`;

  const query_22 = {};

  const url_22 = queryString.stringifyUrl({ url: path_22, query: query_22 });

  //   23
  const path_23 = `${BASE_URL}/activation-programs-pdf`;

  const query_23 = {
    webinar_summary: _stringify(webinar_summary),
    startDate: start_date,
    endDate: end_date
  };

  const url_23 = queryString.stringifyUrl({ url: path_23, query: query_23 });
  const pages_1 = [
    url_1,
    url_2,
  ];

  const pages_2 =
    is_fwd_org ?
      [
        url_3
      ] : [];
  const pages_3 = [
    url_4,
    url_5,
    url_6,
    url_7,
    url_8,
    url_9,
    url_10,
    url_11,
    url_12,
    url_13,
    url_14,
    url_15,
    url_16,
    url_17,
    url_18,
    url_19,
    url_20,
    url_21,
    url_22,
    url_23
  ];

  const activation_programs_detail_pages = (webinars || [])?.map((webinar: any) => {

  const query = {
    webinar: _stringify(webinar),
    startDate: start_date,
    endDate: end_date
  };

  return [
    queryString.stringifyUrl({ url:  `${BASE_URL}/activation-programs-detail-overview-pdf`, query: query }),
    queryString.stringifyUrl({ url:  `${BASE_URL}/activation-programs-detail-impact-rating-pdf`, query: query })
  ] 
  })
  
  console.log('activation_programs_detail_pages', activation_programs_detail_pages.flat())
  
  const pages = pages_1.concat(pages_2, pages_3, activation_programs_detail_pages.flat())
  console.log('webinars', webinars)
  console.log("pages", pages);
  return pages;
};

export default get_pages;
