import OverViewCard1 from './Components/OverViewCard1'
import OverViewCard2 from './Components/OverViewCard2'
import { useDispatch, useSelector } from "react-redux";
import SectionLabel from 'components/Section'
import { applyTypeFilterDatetime, selectAuth } from 'containers/SingIn/authSlice';
import { isEmpty } from 'lodash';
import DepartmentHeath from './Components/DepartmentHeath';
import Chart from 'components/Chart';
import {
  REACT_APP_METABASE_FWD_METRICS
} from 'constants/megabase';
import {
  REACT_APP_METABASE_AIA_METRICS
} from 'constants/megabase';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { refreshProgramAction } from 'containers/SingIn/authAPI';
import queryString from 'query-string';
import { add_params_and_push } from 'utils';
import FilterValue from 'containers/Layout/FilterBar/filter_value';

const Overview = () => {
  const dispatch = useDispatch();

  const {
    program,
    org,
    webinar_summary,
    app,
    departmental_health_overview,
    is_dependent,
    is_referral_user,
    is_fwd_org,
    is_aia_org,
    video_therapy_session_count
  } = useSelector(selectAuth);

  const history = useHistory()
  const location = useLocation();

  useEffect(() => {
    console.log('123', is_fwd_org)
    dispatch(applyTypeFilterDatetime(0));
    dispatch(refreshProgramAction(location.search));

    add_params_and_push({ typeFilterDatetime: 0 }, location, queryString, history)

    // history.push({
    //   state: { isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true }
    // });
  }, []);


  const webinarsView = webinar_summary && !isEmpty(webinar_summary.type) && webinar_summary.type.map((item: any, index: number) => {
    for (const [key, value] of Object.entries(item)) {
      return <OverViewCard1
        key={'OverViewCard1-' + index}
        className={'bg-white shadow sm:rounded-lg flex-1'}
        title={key} value={value || 0}
        description={"Webinars"}
        colorTitle={"#004876"}
        bgDescription={"rgba(234, 250, 247, 0.4)"}
      />
    }
    return null

  })

  const dataOverview_1 =
    [
      {
        key: 'OverViewCard1-2',
        className: 'bg-white shadow sm:rounded-lg flex-1',
        title: '1ST LEVEL OF SUPPORT',
        value: program?.level_1 || 0,
        unit: "headcount",
        subtitle: "Preventive Care​",
        description: 'ThoughtFullChat App Coaching',
        listSubDescription: ["Unlimited text & audio messages​",
          "Asynchronous response within 24 hours Mon-Fri"],
        bgDescription: "rgba(234, 250, 247, 0.5)",
        colorTitle: "#004876",
        fontWeight: 600
      },
      {
        key: 'OverViewCard1-3',
        className: 'bg-white shadow sm:rounded-lg flex-1',
        title: '2ND LEVEL OF SUPPORT',
        value: program?.level_2 || 0,
        unit: "sessions",
        subtitle: "Curative Care​",
        description: 'Individual Therapy/ Counselling Sessions',
        listSubDescription: ["60 minute video therapy sessions"],
        bgDescription: "rgba(250, 249, 234, 0.5)",
        colorTitle: "#004876",
        fontWeight: 600
      },
      {
        key: 'OverViewCard1-4',
        className: 'bg-white shadow sm:rounded-lg flex-1',
        title: '3RD LEVEL OF SUPPORT',
        subtitle: "Crisis Intervention",
        value: program?.level_3 || 0,
        unit: "calls",
        description: '24/7 Wellness Hotline & Crisis Intervention',
        listSubDescription: ["Telephone psychological first aid and triaging"],
        bgDescription: "rgba(250, 234, 234, 0.5)",
        colorTitle: "#004876",
        fontWeight: 600
      }
    ]
  const utilisation = [
    // {
    //   key: "OverViewCard2-1",
    //   percent: true,
    //   className: 'bg-white shadow sm:rounded-lg flex-1',
    //   value: app?.first_time_engaging_with_mental_health,
    //   description: 'First time engaging with mental health services',
    // },
    {
      total_active_user: app?.total_active_user,
      key: 'OverViewCard2-2',
      percent: true,
      className: 'bg-white shadow sm:rounded-lg flex-1',
      value: org?.code == "MONKEY_1" ? "70" : app?.app_rate,
      description: 'App Utilisation Rate Across the Organisation',
    },
    {
      total_active_coachees: app?.total_active_coachees,
      key: 'OverViewCard2-3',
      percent: true,
      className: 'bg-white shadow sm:rounded-lg flex-1',
      value: app?.engaging_with_mental_health_course,
      description: 'Are engaging with mental health coaches',
    },
    {
      total_active_coachees: app?.total_active_coachees,
      key: 'OverViewCard2-3',
      percent: true,
      className: 'bg-white shadow sm:rounded-lg flex-1',
      value: app?.self_serve_users_percent,
      description: 'have engaged with a self-awareness tool',
    }
  ]
  console.log('is_dependent', is_dependent)
  console.log('is_referral_user', is_referral_user)
  return (
    <>
      <main>
        <div className="py-6 bg-grey min-h-screen">
          <FilterValue />
          <div className=" mx-auto px-1 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-blue">Overview of {org?.name}'s Mental Wellbeing Program</h1>
            {
              !is_dependent && !is_referral_user && (
                <>
                  <div className="mt-5">
                    <SectionLabel key={'SectionLabel-1'} title={`Total Available Support Coverage`} />

                  </div>
                  <div className="flex flex-wrap justify-between space-x-8 ">
                    {
                      dataOverview_1.map(data => (
                        <OverViewCard1
                          key={data.key}
                          className={data.className}
                          title={data.title}
                          value={data.value}
                          unit={data.unit}
                          subtitle={data.subtitle}
                          description={data.description}
                          listSubDescription={data.listSubDescription}
                          bgDescription={data.bgDescription}
                          colorTitle={data.colorTitle}
                          fontWeight={data.fontWeight}
                        />
                      ))
                    }
                  </div>
                </>
              )
            }

          </div>
          {
            !is_dependent && !is_referral_user && (
              <>
                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
                  <SectionLabel key={'SectionLabel-2'} title={`Total Available Activation Programs`} />
                  <div className="flex flex-wrap justify-between space-x-8 ">
                    {webinarsView}
                  </div>
                </div>
              </>
            )
          }

          {
            !is_referral_user && (
              <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
                <SectionLabel key={'SectionLabel-3'} title="ThoughtFullChat App Utilisation" />
                <div className="flex flex-wrap justify-between space-x-8 ">
                  {utilisation.map(data => (
                    <OverViewCard2
                      key={data.key}
                      percent={data.percent}
                      className={data.className}
                      value={data.value}
                      description={data.description}
                      total_active_user={data.total_active_user}
                      total_active_coachees={data.total_active_coachees}
                    />
                  ))}
                </div>
              </div>
            )
          }

          {
            !is_dependent && !is_referral_user && (
              <>
                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10 ">
                  <SectionLabel
                    tooltip={"Note: Only departments with more than 10 active users will be displayed"}
                    key={'SectionLabel-3'} title="Departmental Health" />
                  <div className="flex flex-wrap justify-between space-x-8 mb-10">
                    <DepartmentHeath departmental_health_overview={departmental_health_overview} />
                  </div>
                </div>
                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10 flex">
                  <div className="w-1/2 ">
                    <SectionLabel key={'SectionLabel-3'} title="Individual Therapy/ Counselling Sessions Utilisation" />
                    <div className="flex flex-wrap justify-between space-x-8 ">
                      <div className={"bg-white shadow sm:rounded-lg flex-1"}>
                        <div className="px-4 py-5 sm:p-6 bg-white">

                          <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                            <p>{
                              org?.code == "MONKEY_1" ? 151 :
                                (video_therapy_session_count || video_therapy_session_count == 0 ? video_therapy_session_count : "-")}
                              <span style={{ color: "#59817D" }} className="text-sm font-normal ml-2">
                                sessions were utilised
                              </span></p>

                          </div>
                        </div>

                      </div >
                    </div>
                  </div>
                  <div className="w-1/2  ml-6 pb-8">
                    <SectionLabel key={'SectionLabel-3'} title="24/7 Wellness Hotline & Crisis Intervention Utilisation" />
                    <div className="flex flex-wrap justify-between space-x-8 ">
                      <div className={"bg-white shadow sm:rounded-lg flex-1"}>
                        <div className="px-4 py-5 sm:p-6 bg-white">

                          <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                            <p>{program?.level_3_report || program?.level_3_report == 0 ? program?.level_3_report : "-"}
                              <span style={{ color: "#59817D" }} className="text-sm font-normal ml-2">
                                calls were made
                              </span></p>

                          </div>
                        </div>

                      </div >
                    </div>
                  </div>
                </div>
              </>
            )
          }
          {
            !!is_fwd_org && !is_dependent && (
              <div className=" mx-auto px-1 sm:px-6 md:px-8">
                <div className="flex flex-wrap justify-between space-x-8 pt-8 ">
                  <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_FWD_METRICS} />
                </div>
              </div>
            )
          }
          {
            !!is_aia_org && !is_dependent && (
              <div className=" mx-auto px-1 sm:px-6 md:px-8">
                <div className="flex flex-wrap justify-between space-x-8 pt-8 ">
                  <Chart height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_AIA_METRICS} />
                </div>
              </div>
            )
          }
        </div>
      </main>
    </>
  )
}

export default Overview