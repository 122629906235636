
import SectionLabel from 'components/Section';

const MetricsDefinition = (props: any) => {
    const data = [
        {
            metrics: "Total Eligible Clients",
            definition: "No. Of clients who signed the AIA CS Mental Health Solutions policy",
        },
        {
            metrics: "Total Onboarded",
            definition: "No. Of clients who completed HR onboarding session with TF",
        },
        {
            metrics: "% Onboarded",
            definition: "Total Onboarded/Total Eligible Clients",
        },
        {
            metrics: "Launched",
            definition: "No. Of clients who completed virtual launch session to all employees",
        },
        {
            metrics: "% Launched",
            definition: "Launched/Total Eligible Clients",
        },
        {
            metrics: "Engagement Rate",
            definition: "No. Of clients who engaged in meaningful activities on app/Total Registered Users",
        },
        {
            metrics: "Renewal",
            definition: "Clients who renewed the policy",
        },
        {
            metrics: "% Renewal",
            definition: "Renewal/Total Eligible Clients",
        },
        
    ]
    return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <div 
        id="myiFrame"
        className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
            <SectionLabel key={'SectionLabel-3'} title="Metrics Definition" />
            <div className="flex flex-wrap justify-between space-x-8  mb-10 w-full">
            <div 
            style={{ borderColor: "#E5E7EB" }}
            className="flex flex-wrap justify-between space-x-8  w-full border-solid border rounded-md">
                <div
                    style={{ borderColor: "#E5E7EB" }}
                    className="w-full flex flex-col  border-solid border rounded-md">
                    <div className="w-full -my-2 overflow-x-auto">
                        <div className="w-full py-2 align-middle inline-block ">
                            <div className="w-full shadow">
                                <table
                                    // style={{ minWidth: "1550px" }}
                                    className="divide-y divide-grey1 w-full">
                                    <thead className="bg-white">
                                        <tr>
                                            <th
                                                style={{ color: "#4B5563" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                METRICS
                                            </th>
                                            <th
                                                 style={{ color: "#4B5563" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                DEFINITION
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((item: any, personIdx: number) => (
                                            <tr
                                                // onClick={() => handleActiPrItem(item.id)}
                                                key={item.id}
                                                // style={{ cursor: "pointer" }}
                                                className={`${personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'} hover:bg-[#e6fcf9]`}>

                                                <td 
                                                style={{ color: "#4B5563" }}
                                                className="px-6 py-4   text-sm font-medium text-gray-900">
                                                    {item.metrics}</td>
                                                <td
                                                style={{ color: "#4B5563" }}
                                                className="px-6 py-4   text-sm text-gray-500 ">{item.definition}</td>
                                               </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default MetricsDefinition;
