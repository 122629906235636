import React from 'react'


const DbCircleChart = (props: any) => {
    const  formatNumber = (num:any) => {
        // Check if the number has any decimal places
        if (num && num % 1 !== 0) {
          // If it does, format it to 2 decimal places
          return parseFloat(num.toFixed(1));
        }
        if (num && num % 1 == 0) {
          // If it does, format it to 2 decimal places
          return num?.toLocaleString('en-US')
        }
        // If it's an integer, return it as is
        return num;
      }
    return (
        <div key={props.key} id="db-circle-chart-container">
            <div className="circle large">{formatNumber(props.year) || "0"}</div>
            <div className="circle small">{formatNumber(props.month) || "0"} </div>
        </div>
    )
}


export default DbCircleChart;