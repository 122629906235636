import ReactTooltip from "react-tooltip"
type IconTooltipProps = {
    style?: any;
    dataFor: string;
    title: string
}
const IconTooltip = (props: IconTooltipProps) => {
    const {
        dataFor,
        style
    } = props
    return (
        <>
            <svg
                style={style || {}}
                data-tip data-for={dataFor}
                xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 " viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>

            <ReactTooltip id={dataFor} className="normal-case" >
                <span>{props.title}</span>
            </ReactTooltip>
        </>
    )
}
export default IconTooltip;