
import ChartPdf from '.'
import {
    REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE, 
    REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG
} from 'constants/megabase'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const Component = () => {
    const location = useLocation();

    const query = queryString.parse(location.search);
    const department = `${query.department}` || null;

const question_monthly_dass = department ? REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG_WITH_BASE_LINE : REACT_APP_METABASE_MONTHLY_DASS_SCORE_OF_ORG

  return (
    <main>
    <div className="bg-grey px-4">
      <div className="mx-auto">
      </div>
      <div className="">
        <div className="pt-2">
          <div className="justify-between">
          <ChartPdf height={"95vh"} className={'bg-white shadow sm:rounded-lg flex-1'} question={question_monthly_dass} />
          </div>
        </div>
      </div>
    </div>
  </main>
  )
}

export default Component
