
import { useSelector } from 'react-redux'
import BoxTitle from 'components/BoxTitle';
import { selectAuth } from 'containers/SingIn/authSlice';
import { useLocation } from 'react-router-dom';
import SummaryItem from 'containers/ActivationPrograms/components/SumaryItem';


type SummaryProps = {
    className: string
    title: string
    webinar_summary?: any;
}
const Summary = (props: SummaryProps) => {
    const {
        webinar_summary
    } = props;
    const summarys = [
        {
            key: 'summary-chart-number_participant',
            rate:  webinar_summary?.total_participants,
            title: 'Total Participants',
            color: 'bg-green',
        }, {
            key: 'summary-chart-average_engagement_rate',
            rate:  webinar_summary?.average_engagement_rate,
            title: `Average Engagement\nRate`,
            color: 'bg-yellow2',
            tooltip: "User engagement during webinar through survey and chat participation"
        },
        {
            key: 'summary-chart-extended_qa_retention_rate',
            rate: webinar_summary?.extended_qa_retention_rate,
            title: 'Extended Q&A Retention Rate',
            color: 'bg-red',
            tooltip: "Participants who stayed back for the Q&A at the end of the session"
        },
        {
            key: 'summary-chart-average_webinar_ratings',
            rate:  webinar_summary?.average_webinar_ratings,
            title: 'Average Webinar Rating',
            color: 'bg-primary',
            subDes: "out of 5"
        }
    ]
    return (
        <div className={props.className}>
            <div className="">
                <BoxTitle title={props.title} />
                <div className="flex flex-wrap justify-evenly p-5 -mt-5">
                    {
                        props.title === "Summary" && <SummaryItem rate={webinar_summary?.total_webinar || 0} title='Total Webinars' color='bg-blue' />
                    }
                    {summarys.map((s, index) => (
                        <SummaryItem
                            key={s.key}
                            rate={s.rate}
                            title={s.title}
                            color={s.color}
                            subDes={s.subDes}
                            tooltip={s.tooltip}
                            index={index}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Summary
