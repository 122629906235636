import IconTooltip from "components/IconTooltip"

type SectionLabelProps = {
    title: string,
    tooltip?: string
}
const SectionLabel = (props: SectionLabelProps) => {
    return (
        <div className="flex flex-row justify-start items-center">
            
            {
                props.tooltip ? (
                    <>
                    <h3 className="text-xl font-normal text-blue py-3">{props.title}</h3>
                    <div className="ml-1 mr-5">
                        <IconTooltip
                            dataFor={`dataFor-Section`}
                            title={props.tooltip}
                        />

                    </div>
                    </>
                  
                )
                :
                <h3 className="text-xl font-normal mr-5 text-blue py-3">{props.title}</h3>
            }
            <span className="flex-1 bg-grey1 h-0.25"></span>

        </div>
    )
}
export default SectionLabel