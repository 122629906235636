


const convertRatingToArr = (num: number) => {
    let arrStar = []
    for (let i = 1; i <= 5; i++) {
        if (i < num) {
            arrStar[i - 1] = "fas fa-star "
        } else if (i === Math.round(num)) {
            if (i === num) {
                arrStar[i - 1] = "fas fa-star "
            } else {
                arrStar[i - 1] = "fas fa-star-half-alt "
            }
        } else {
            arrStar[i - 1] = "far fa-star "
        }
    }
    return arrStar
}
interface RatingBarOnlyProps {
    rating: number
    className?: string
    starWidth: string
}

const RatingBarOnly = (props: RatingBarOnlyProps) => {
    const renderStart = convertRatingToArr(props.rating).map((item, index) => {
        return <li key={'RatingBar-' + index}>
            <i className={item + " fa-" + props.starWidth + " text-yellow mr-1"}></i>
        </li>
    })

    return (
        <div className={props.className}>
            <div className="px-4">
                <ul className="flex justify-center space-x-1">
                    {renderStart}
                </ul>
            </div>
        </div>
    )
}

export default RatingBarOnly
