import { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { clearMessEmail, selectAuth } from "containers/SingIn/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { sendEmailAction } from 'containers/SingIn/authAPI';
const TypeEmail = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('')
    const { messageSendEmail } = useSelector(selectAuth);
    const onFocus = () => {
        dispatch(clearMessEmail());
    }
    const submitEmail = () => {
        const em = email ? email.toLowerCase() : email;
        dispatch(sendEmailAction(em));
    }

    return (
        <>
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray500">
                    Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        onFocus={onFocus}
                        value={email}
                        onKeyPress={(e) => e.key === 'Enter' && submitEmail()}
                        onChange={(evt) => setEmail(evt.target.value)}
                        autoComplete="otp"
                        required
                        className={`${messageSendEmail && "input-error"}
                        appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        aria-invalid="true"
                        aria-describedby="email-error"
                    />
                    {
                        messageSendEmail === "" ? null :
                            <div className={`${messageSendEmail && "text-error"} absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`}>
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                    }

                </div>
                <p className="mt-2 text-sm text-red-600 text-error">
                    {messageSendEmail}
                </p>
            </div>
            <div>
                <button
                    onClick={submitEmail}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-normal text-white bg-primary hover:bg-primaryHeavy focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Request Email OTP
                </button>
            </div>
            {/* <div className="flex items-center justify-center">
                <div className="text-sm">
                    <a href="#" className="font-normal text-primary hover:text-indigo-500">
                        Forgot your email address?
                    </a>
                </div>
            </div> */}
        </>
    )
}
export default TypeEmail
