

import BoxTitle from "components/BoxTitle";
import { selectAuth } from "containers/SingIn/authSlice";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux'

interface PieChartViewProps {
    className: string
    title: string
}

const PieChartView = (props: PieChartViewProps) => {
    const { app, org } = useSelector(selectAuth);

    return (
        <div className={props.className}>
            <div className="">
                <BoxTitle title={props.title} />
                <div className="flex flex-row justify-center space-x-20 pb-10 ">
                    <div className="w-56 flex flex-col items-center">
                        <span className="pb-5 text-2xl font-normal text-blue" >Learn</span>
                        <CircularProgressbar value={app?.engagement_learn ?? 0} text={`${app?.engagement_learn ?? 0}%`} />
                        <div className="text-center  text-gray500 mt-6 leading-6 font-normal">
                            of the active users enrolled and engaged with learn packs, lessons, breathing timers and audio guides
                        </div>
                    </div>
                    <div className="w-56 flex flex-col items-center">
                        <span className="pb-5 text-2xl font-normal text-blue" >Chat</span>
                        <CircularProgressbar value={org?.code == "MONKEY_1" ? 54 : app?.engagement_chat ?? 0} text={`${org?.code == "MONKEY_1" ? 54 : app?.engagement_chat ?? 0}%`} />
                        <div className="text-center text-gray500 mt-6 leading-6	font-normal">
                            of the active coachees that engaged with their mental health professional
                        </div>
                    </div>
                    <div className="w-56 flex flex-col items-center">
                        <span className="pb-5 text-2xl font-normal text-blue" >Grow</span>
                        <CircularProgressbar value={org?.code == "MONKEY_1" ? 76 : app?.engagement_grow ?? 0} text={`${org?.code == "MONKEY_1" ? 76 : app?.engagement_grow ?? 0}%`} />
                        <div className="text-center text-gray500 mt-6 leading-6	font-normal">
                            of the active users that logged their mood, thought journals and emotional health assessments
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PieChartView
