import {
    XIcon
} from '@heroicons/react/outline';

const CloseButton = (props: any) => {
   
    return (
        <div className="absolute top-0 right-0 z-30">
        <button
            type="button"
            className="cursor-pointer ml-1 flex items-center justify-center h-10 w-12 rounded-full focus:outline-none"
            onClick={() => props?.setFilterbarClose(false)}
        >
            <XIcon className="h-6 w-6 text-gray" aria-hidden="true" />
        </button>
    </div>

    )
}
export default CloseButton