import { useDispatch, useSelector } from "react-redux";
import SectionLabel from 'components/Section'
import { applyTypeFilterDatetime, selectAuth } from 'containers/SingIn/authSlice';

import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { doExportPdf, getMetabaseData, refreshProgramAction } from 'containers/SingIn/authAPI';
import queryString from 'query-string';
import { add_params_and_push } from 'utils';
import ActivationProgrammeWebinars from "containers/AiaCs/BehaviouralInsights/activation_programme_webinars"
import Overview from "./Overview";
import TopUserOutcomes from "./BehaviouralInsights/top_user_outcomes";
import HealthOutcomes from "./HealthOutcomes";
import images from "assets/images";
import ExportPdfButton from "./ExportPDF/button";
import get_pages from "containers/AiaCs/ExportPDF/pages";
import { REACT_APP_METABASE_AIA_TOTAL_REGISTER_USERS, REACT_APP_METABASE_AIA_OVERALL_USER_RATING, REACT_APP_METABASE_AIA_ACTIVATION_PROGRAMME_WEBINARS, REACT_APP_METABASE_AIA_TOP_3_SELECTED_TOPICS, REACT_APP_METABASE_AIA_TOP_USER_OUTCOMES, REACT_APP_METABASE_AIA_NUMBER_COMPLETED_DASS } from "constants/megabase";
import MetricsDefinition from "./MetricsDefinition";
const { PDFDocument } = require('pdf-lib');

const AiaCs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory()

  const { year } = useSelector(selectAuth);
  const _year = new URLSearchParams(location.search).get('year') || year;
  const month_quarter = new URLSearchParams(location.search).get('month_quarter') || "Entire year"

  const [totalRegisterUsers, setTotalRegisterUsers] = useState(0)
  const [overallUserRating, setOverallUserRating] = useState(0)
  const [webinars, setWebinars] = useState([])
  const [top3Topics, setTop3Topics] = useState([])
  const [topUserOutcomes, setTopUserOutcomes] = useState([])
  const [numberCompletedDass, setNumberCompletedDass] = useState(0)

  const is_apply_filter = new URLSearchParams(location.search).get('is_apply_filter');

  useEffect(() => {
    dispatch(applyTypeFilterDatetime(0));
    dispatch(refreshProgramAction(location.search));

    add_params_and_push({ typeFilterDatetime: 0 }, location, queryString, history)
  }, []);

  useEffect(() => {
    get_data()
  }, [is_apply_filter]);


  const get_data = async () => {
    const res_1: any = await getMetabaseData({
      question_id: Number(REACT_APP_METABASE_AIA_TOTAL_REGISTER_USERS),
      year: _year
    })

    const res_2: any = await getMetabaseData({
      question_id: Number(REACT_APP_METABASE_AIA_OVERALL_USER_RATING),
      year: _year
    })

    const res_3: any = await getMetabaseData({
      question_id: Number(REACT_APP_METABASE_AIA_ACTIVATION_PROGRAMME_WEBINARS),
      year: _year,
      month: month_quarter
    })

    const res_4: any = await getMetabaseData({
      question_id: Number(REACT_APP_METABASE_AIA_TOP_3_SELECTED_TOPICS),
      year: _year,
      month: month_quarter
    })

    const res_5: any = await getMetabaseData({
      question_id: Number(REACT_APP_METABASE_AIA_TOP_USER_OUTCOMES),
      year: _year,
      month: month_quarter
    })

    const res_6: any = await getMetabaseData({
      question_id: Number(REACT_APP_METABASE_AIA_NUMBER_COMPLETED_DASS),
      year: _year
    })

    Promise.all([res_1, res_2, res_3, res_4, res_5, res_6])
      .then((responses) => {
        console.log('responses', responses)
        getDataRes1(responses)

        getDataRes2(responses)
        getDataRes3(responses)


        getDataRes4(responses)

        getDataRes5(responses)

      })
      .catch((error) => {
        // Handle errors from any of the API calls
        console.error('Error:', error.message);
      });

  }
  const formatNumber = (num: any) => {
    // Check if the number has any decimal places
    if (num && num % 1 !== 0) {
      // If it does, format it to 2 decimal places
      return parseFloat(num.toFixed(2));
    }
    // If it's an integer, return it as is
    return num;
  }
  const exportPdf = async () => {

    const pages: any = get_pages({
      year: _year,
      month_quarter: month_quarter,
      totalRegisterUsers: totalRegisterUsers,
      overallUserRating: overallUserRating,
      webinars: webinars,
      top3Topics: top3Topics,
      topUserOutcomes: topUserOutcomes,
      numberCompletedDass: numberCompletedDass
    })
    console.log('pages', pages)
    
    if (pages && pages.length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      loading(10000)
      await Promise.all(pages.map((page: any) => retryApiCall(getBase64Pdf, { url: page }, 2)))
        .then(async (completedTasks) => {
          console.log('completedTasks', completedTasks)
          const pdfBase64s: any = completedTasks?.map((data: any) => {
            return data.data?.data?.data?.data
          })
          console.log('pdfBase64s', pdfBase64s)
          const data = pdfBase64s.filter((pdfBase64: any) => { return !!pdfBase64 })
          const mergedPdfString = await mergePDFs(data)
          if (mergedPdfString) {
            downloadPDF(mergedPdfString)
          }

        }).catch((error) => {
          console.log('error', error)
        });
      cancelLoading()
    }

  }
  const mergePDFs = async (pdfStrings: any) => {
    try {

      const pdfDoc = await PDFDocument.create();

      for (const pdfBase64String of pdfStrings) {

        const pdfBytes = base64StringToBuffer(pdfBase64String);
        const pdf = await PDFDocument.load(pdfBytes);

        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());

        copiedPages.forEach((page: any) => pdfDoc.addPage(page));

      }
      const mergedPdfBytes = await pdfDoc.saveAsBase64();
      console.log('mergedPdfBytes', mergedPdfBytes)
      return mergedPdfBytes;

    } catch (error) {
      console.log('error', error)
      return null;
    }
  }
  const base64StringToBuffer = (base64String: string) => {
    // Decode the base64 string into binary data
    const binaryData = atob(base64String);

    // Create a Uint8Array from the binary data
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a buffer from the Uint8Array
    const buffer = byteArray.buffer;

    return buffer;
  }


  const downloadPDF = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "tf_insights.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const retryApiCall = (apiFunction: any, args: any, maxRetries: number) => {
    console.log('111', 111)
    return new Promise(async (resolve, reject) => {
      for (let retryCount = 0; retryCount <= maxRetries; retryCount++) {
        try {
          const response = await apiFunction(args);
          if (response && response.data?.data?.data?.data) {
            resolve(response);
            return;
            // Exit the loop if successful
          }
        } catch (error) {
          if (retryCount === maxRetries) {
            reject(error); // Reject if max retries reached
          }
        }
      }
    });
  }

  const getBase64Pdf = async (requestBody: any) => {
    return doExportPdf(requestBody)
  }


  const loading = (time: number) => {
    console.log("loading...")
    let spinner: any = document.getElementById("spinner");
    spinner.style.display = "block";
    let loadingOverlay: any = document.getElementById("loadingOverlay");
    loadingOverlay.style.display = "block";
    let ctx: any = spinner.getContext("2d");
    let width = spinner.width;
    let height = spinner.height;
    let degrees = 0;
    let new_degrees = 0;
    let difference = 0;
    let bgcolor = "#FFFFFF";
    let text;
    let animation_loop: any;

    function init() {
      ctx.clearRect(0, 0, width, height);

      ctx.beginPath();
      ctx.strokeStyle = bgcolor;
      ctx.lineWidth = 30;
      ctx.arc(width / 2, width / 2, 100, 0, Math.PI * 2, false);
      ctx.stroke();
      let radians = degrees * Math.PI / 180;

      ctx.beginPath();
      ctx.strokeStyle = "#009383";
      ctx.lineWidth = 30;
      ctx.arc(width / 2, height / 2, 100, 0 - 90 * Math.PI / 180, radians - 90 * Math.PI / 180, false);
      ctx.stroke();
      ctx.fillStyle = "#FFFFFF";
      ctx.font = "50px arial";
      text = Math.floor(degrees / 360 * 99) + "%";
      let text_width = ctx.measureText(text).width;
      ctx.fillText(text, width / 2 - text_width / 2, height / 2 + 15);
    }

    function draw() {
      if (typeof animation_loop != undefined) clearInterval(animation_loop);
      new_degrees = 360;
      difference = new_degrees - degrees;
      animation_loop = setInterval(animate_to, time / difference);
    }

    function animate_to() {
      if (degrees == new_degrees)
        clearInterval(animation_loop);
      else if (degrees < new_degrees)
        degrees++;
      else
        degrees--;
      init();
    }

    draw();
  }

  const cancelLoading = () => {
    console.log("cancel loading!")
    let spinner: any = document.getElementById("spinner");
    spinner.style.display = "none";
    let loadingOverlay: any = document.getElementById("loadingOverlay");
    loadingOverlay.style.display = "none";
  }

  const getDataRes1 = (responses: any) => {
    if (responses[0] && responses[0]?.data?.data?.data?.rows?.length > 0 &&
      responses[0]?.data?.data?.data?.rows[0]?.length > 1) {
      const total_register_users = responses[0]?.data?.data?.data?.rows[0][1] || 0
      setTotalRegisterUsers(total_register_users)
    } else {
      setTotalRegisterUsers(0)
    }
    if (responses[1] && responses[1]?.data?.data?.data?.rows?.length > 0 &&
      responses[1]?.data?.data?.data?.rows[0]?.length > 0) {
      const overall_user_rating = responses[1]?.data?.data?.data?.rows[0][0]?.toFixed(2) || 0
      setOverallUserRating(overall_user_rating)
    } else {
      setOverallUserRating(0)

    }
  }
  const getDataRes2 = (responses: any) => {
    if (responses[2] && responses[2]?.data?.data?.data?.rows?.length > 3) {
      const webinars = responses[2]?.data?.data?.data?.rows || []
      setWebinars(webinars)
    } else {
      setWebinars([])
    }
  }
  const getDataRes3 = (responses: any) => {
    if (responses[3] && responses[3]?.data?.data?.data?.rows?.length > 2) {
      const top_3_topics = responses[3]?.data?.data?.data?.rows || []
      setTop3Topics(top_3_topics)
    } else {
      setTop3Topics([])
    }
  }
  const getDataRes4 = (responses: any) => {
    if (responses[4] && responses[4]?.data?.data?.data?.rows?.length > 0) {
      let data = responses[4]?.data?.data?.data?.rows || []

      let total = 0;

      data.sort((a: any, b: any) => b[1] - a[1]);

      data?.map((r: any) => { total += r[1] })
      data = data?.map((a: any) => {
        return {
          text: a[0] == "" ? "Others" : a[0],
          value: formatNumber((a[1] / total) * 100)
        }
      })
      setTopUserOutcomes(data)
    } else {
      setTopUserOutcomes([])
    }
  }
  const getDataRes5 = (responses: any) => {
    if (responses[5] && responses[5]?.data?.data?.data?.rows?.length > 0 &&
      responses[5]?.data?.data?.data?.rows[0]?.length > 0) {
      const data = responses[5]?.data?.data?.data?.rows[0][0] || 0
      setNumberCompletedDass(data)
    } else {
      setNumberCompletedDass(0)
    }
  }

  return (
    <>
      <canvas id="spinner" width="300" height="300" />
      <div id="loadingOverlay">
      </div>
      <main>
        <div className="py-6 bg-grey min-h-screen">
          <div className=" mx-auto px-1 sm:px-6 md:px-8 flex justify-between">
            <div>
              <h1 className="text-2xl font-semibold text-blue">{`THOUGHTFULL X AIA CS - ${_year} ${month_quarter} REVIEW`.toUpperCase()}</h1>

            </div>
            <div className="flex">
              <div>
                <img
                  className="h-24 w-auto"
                  style={{ width: '175px', height: '46px' }}
                  src={images.tf_aia_logo}
                  alt="Workflow"
                />
              </div>
              <div>
                <img
                  className="h-24 w-auto"
                  style={{ width: '126px', height: '46px' }}
                  src={images.aia_logo}
                  alt="Workflow"
                />
              </div>

            </div>
          </div>
          <ExportPdfButton exportPdf={exportPdf} />
          {/* Overview */}
          <Overview totalRegisterUsers={totalRegisterUsers} overallUserRating={overallUserRating} />

          {/* Behavioural Insights */}

          <div className={"mx-auto px-1 sm:px-6 md:px-8 mt-10"}>
            <SectionLabel key={'SectionLabel-3'} title="Behavioural Insights" />

            <ActivationProgrammeWebinars webinars={webinars} top3Topics={top3Topics} />
            <TopUserOutcomes topUserOutcomes={topUserOutcomes} />
          </div>

          {/* Health Outcomes */}

          <HealthOutcomes numberCompletedDass={numberCompletedDass} />

          <MetricsDefinition />
        </div>
      </main>
    </>
  )
}

export default AiaCs