import {
  REACT_APP_METABASE_FWD_METRICS
} from 'constants/megabase'
import {
  REACT_APP_METABASE_AIA_METRICS
} from 'constants/megabase'
import { useLocation } from 'react-router-dom';
import ChartPdf from 'containers/ExportPdf/ChartPdf';

const FwdMetricChartPdf = () => {

  const location = useLocation();
  const is_fwd_org = new URLSearchParams(location.search).get('is_fwd_org') == 'true';
  const is_aia_org = new URLSearchParams(location.search).get('is_aia_org') == 'true';

  return (
    <>
      <main>
        {
          !!is_fwd_org && (
            <ChartPdf height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_FWD_METRICS} />
          )
        }
        {
          !!is_aia_org && (
            <ChartPdf height={"600px"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_AIA_METRICS} />
          )
        }
      </main>
    </>
  )
}

export default FwdMetricChartPdf