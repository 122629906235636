import { 
    REACT_APP_METABASE_AIA_BREAKDOWN_GENDER } from 'constants/megabase';
import ChartPdf from 'containers/ExportPdf/ChartPdf';

const AiaBreakDownGenderChart = (props: any) => {
   
   return (
       // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
       <main id="myiFrame">
       <div className="flex">
           <ChartPdf height={"95vh"} className={'bg-white shadow flex-1 mt-6'} question={REACT_APP_METABASE_AIA_BREAKDOWN_GENDER} />
       </div>
       </main>

   )
}

export default AiaBreakDownGenderChart;
