type BoxTitleProps = {
    title: string
    borderNone?: boolean;
}
const BoxTitle = (props: BoxTitleProps) => {
    return (
        <h3
            style={{ color: "#4c5773" }}
            className={`text-xl px-4 leading-6 font-semibold py-5 border-grey1 mb-5`}>{props.title}</h3>
    )
}
export default BoxTitle