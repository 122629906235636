import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import SectionLabel from 'components/Section';
import Chart from 'components/Chart';
import { REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT } from 'constants/megabase';

const Overview = (props: any) => {
    const location = useLocation();
    const { year } = useSelector(selectAuth);
    const _year = new URLSearchParams(location.search).get('year') || year;
    const formatNumber = (num: any) => {
        // Check if the number has any decimal places
        console.log('first', typeof num)
        if (num && num % 1 !== 0) {
          // If it does, format it to 1 decimal places
          return (parseFloat(num).toFixed(1));
        }
        // If it's an integer, return it as is
        return num;
      }
      console.log('props?.overallUserRating', props?.overallUserRating)
    return (
        <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-6">

            <SectionLabel key={'SectionLabel-3'} title="Overview" />
            <div className="text-center" style={{ color: "#004876" }}> <span className="text-xl mr-3">{_year} Total Registered Users: {props?.totalRegisterUsers?.toLocaleString('en-US')}</span>
                        <span className="text-xl ml-3">Overall User Rating: {formatNumber(props?.overallUserRating)}</span>
                    </div>
            <div className="flex flex-wrap justify-between space-x-8  mb-10">

                <Chart
                    height={"600px"}
                    className={'bg-white shadow sm:rounded-lg flex-1 mt-6'}
                    question={REACT_APP_METABASE_AIA_PRODUCT_AND_SERVICE_ENGAGEMENT}
                    is_show_label_bottom={false}
                    label_bottom={<div className="text-center" style={{ color: "#004876" }}> <span className="text-xl mr-3">{_year} Total Registered Users: {props?.totalRegisterUsers}</span>
                        <span className="text-xl ml-3">Overall User Rating: {props?.overallUserRating}</span>
                    </div>
                    }
                />
            </div>
        </div>
    )
}

export default Overview;
