
import React from "react";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ChartBarIcon,
    UsersIcon,
    XIcon,
    DeviceMobileIcon,
    DotsHorizontalIcon,
    LightBulbIcon,
    ViewGridIcon,
    PresentationChartBarIcon,
    DownloadIcon,
    ChartPieIcon
} from '@heroicons/react/outline'

import Topbar from './Topbar';
import {
    Link, useLocation
} from "react-router-dom";
import images from 'assets/images'
import queryString from "query-string";
import { selectAuth } from "containers/SingIn/authSlice";
import { useSelector } from "react-redux";
import TopbarAiaCs from "containers/AiaCs/Topbar/topbar";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const Layout = (props: any) => {
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const location = useLocation();

    const navigation = () => {
        let options =
            [
                { name: 'Overview', href: '/overview', icon: ViewGridIcon, current: true, isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
                { name: 'Utilization Insights', href: '/user-demographics', icon: UsersIcon, current: false, isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
                { name: 'Wellbeing Insights', href: '/app-usage', icon: DeviceMobileIcon, current: false, isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
                { name: 'Behavioral Insight', href: '/clinical-insights', icon: ChartBarIcon, current: false, isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
                { name: 'Activation Programs', href: '/activation-programs', icon: PresentationChartBarIcon, current: false, isShowDatePicker: true, isShowBtnDepartment: false, isShowBtnMartket: false, isShowApplyBtn: true },
                { name: 'Export PDF', href: '/export-pdf', icon: DownloadIcon, current: false, isShowDatePicker: true, isShowBtnDepartment: true, isShowBtnMartket: true, isShowApplyBtn: true },
            ]

        if (window.location.href.includes("tf-aia-cs")) {
            options = [
                { name: 'Thoughtfull X AIA CS', href: '/tf-aia-cs', icon: ChartPieIcon, current: false, isShowDatePicker: true, isShowBtnDepartment: false, isShowBtnMartket: false, isShowApplyBtn: true },
            ]
        }
        return options;
    }

    const {
        org,
    } = useSelector(selectAuth);

    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-primary">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0">
                                    <button
                                        type="button"
                                        className="cursor-pointer ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex items-center flex-shrink-0 px-4 py-4">
                                <img
                                    className="h-24 w-auto"
                                    style={{ width: '170px', height: '45px' }}
                                    src={images.logoTMW}
                                    alt="Workflow"
                                />
                            </div>
                            <div className="flex-1 flex flex-col">
                                <nav className="flex-1 px-2 pb-4 space-y-1">
                                    {navigation().map((item) => (
                                        <Link
                                            key={item.name}
                                            to={{
                                                pathname: item.href,
                                                search: location.search,
                                                state: { isShowDatePicker: item.isShowDatePicker, isShowBtnDepartment: item.isShowBtnDepartment, isShowBtnMartket: item.isShowBtnMartket, isShowApplyBtn: item.isShowApplyBtn }
                                            }}
                                            className={classNames(
                                                window.location.href.includes(item.href) ? 'bg-primaryHeavy text-white' : 'text-indigo-100 hover:bg-primaryHeavy',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            {/* {
                !window.location.href.includes("tf-aia-cs") && ( */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0" >
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow bg-primary overflow-y-auto" >
                    <div className="flex items-center flex-shrink-0 px-4 py-4">
                        <img
                            style={{ width: '170px', height: '45px' }}
                            className="h-24 w-auto"
                            src={images.logoTMW}
                            alt="Workflow"
                        />
                    </div>
                    <div className="flex-1 flex flex-col">
                        <nav className="flex-1 px-2 pb-4 space-y-1">

                            {navigation().map((item) => (
                                <Link
                                    key={item.name}
                                    to={{
                                        pathname: item.href,
                                        search: location.search,
                                        state: { isShowDatePicker: item.isShowDatePicker, isShowBtnDepartment: item.isShowBtnDepartment, isShowBtnMartket: item.isShowBtnMartket, isShowApplyBtn: item.isShowApplyBtn }
                                    }}
                                    className={classNames(
                                        window.location.href.includes(item.href) ? 'bg-primaryHeavy text-white' : 'text-indigo-100 hover:bg-primaryHeavy',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>

            </div>
            <div className="md:pl-64 flex flex-col flex-1 ">
                {
                    window.location.href.includes("tf-aia-cs") ?
                        <TopbarAiaCs setSidebarOpen={setSidebarOpen} /> :
                        <Topbar setSidebarOpen={setSidebarOpen} />
                }
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
export default Layout