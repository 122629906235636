import SectionLabel from 'components/Section'
import { useLocation } from 'react-router-dom';
import PieChartViewPdf from './PieChartPdf';

const AppUsagePdf = () => {
 
    const location = useLocation();

    const decode_and_parse: any = (key: string, defaultValue: string) => {
      const value = new URLSearchParams(location.search).get(key) || defaultValue;
      return JSON.parse(decodeURIComponent(value))
    }
    
    const app = decode_and_parse("app", "{}");

    return (
          // myiFrame id is needed to be able to print to the file (can refer to the lambda function)

    <main id="myiFrame">
      <div className="CardVisualization py-6 bg-grey">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-blue">App Usage</h1>
        </div>
        <div className="py-4">
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            <SectionLabel title="Engagement" />
            <div className="flex flex-wrap justify-between space-x-8  ">
              <PieChartViewPdf app={app} className={'bg-white shadow sm:rounded-lg flex-1'} title={'In-App Sections'} />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AppUsagePdf