/* This example requires Tailwind CSS v2.0+ */

import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from 'containers/SingIn/authSlice';
import { useHistory } from 'react-router';
import { genTokenAction } from 'containers/SingIn/authAPI';
import Logo from "assets/images/logo.png";

const GenToken = (props: any) => {
  const dispatch = useDispatch();
  const { programId } = useSelector(selectAuth);
  const history = useHistory()

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    dispatch(genTokenAction(query.get('uid') || "", history, programId))
  }, []);


  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 px-20 py-20">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex items-center justify-center logo-login" >
              <img
                className="h-20 w-auto my-10"
                src={Logo}
                alt="Workflow"
              />
            </div>
            <div className="">
              <div className="flex flex-col items-center justify-center">
                <div className="text-3xl">
                  <h1 className="font-bold text-primary">
                    Welcome to
                  </h1>
                </div>
                <div className="text-3xl">
                  <h1 className="font-bold text-primary">
                    ThoughtFull Insights
                  </h1>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GenToken