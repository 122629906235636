
import ChartPdf from '.'
import {
    REACT_APP_METABASE_REASON_MOOD
} from 'constants/megabase'

const Component = () => {
  return (
    <main>
    <div className="bg-grey px-4">
      <div className="mx-auto">
      </div>
      <div className="">
        <div className="pt-2">
          <div className="justify-between">
          <ChartPdf height={"95vh"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_REASON_MOOD} />
          </div>
        </div>
      </div>
    </div>
  </main>
  )
}

export default Component
