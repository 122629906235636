
import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useHistory, useLocation } from "react-router-dom";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useEffect, useState } from 'react';
import queryString from "query-string";
import { add_params_and_push } from 'utils';

const DatetimeButton = (props: any) => {
    const history = useHistory()
    const location = useLocation();

    const { handleClickCalendar,} = props;
    const { state: stateRouter } = useLocation<any>();

    const defaultStartDate = new URLSearchParams(location.search).get('startDate') ? moment(new URLSearchParams(location.search).get('startDate')).toDate() : null;
    const defaultEndDate = new URLSearchParams(location.search).get('endDate') ? moment(new URLSearchParams(location.search).get('endDate')).toDate() : null;
    const is_clear_date = new URLSearchParams(location.search).get('is_clear_date') 
    useEffect(() => {
        console.log(11111)
    if(is_clear_date == "true"){
        console.log(22222)
        setDateRange([null, null])
    }
      }, [is_clear_date])
    const [dateRange, setDateRange] = useState([defaultStartDate, defaultEndDate]);
    const [startDate, endDate] = dateRange;

    const handleSetDateRange = (update: any) => {
        const startDate = update[0]
        const endDate = update[1]
        setDateRange(update)
        if (startDate && endDate) {

            add_params_and_push({
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
                is_clear_date: false
            }, location, queryString, history)
        }

        if (!startDate && !endDate) {
            add_params_and_push({
                startDate: null,
                endDate: null
            }, location, queryString, history)
        }
    }
    return (
        <>
            {!!stateRouter?.isShowDatePicker && (
                <>
                    <div style={{ color: "#374151", width: "calc(100% - 4px)"}} className='w-full text-sm mt-4'>
                        <div>Date</div>

                        <div
                            style={{ marginBottom: "0.5rem", zIndex: 500 }}
                            onClick={handleClickCalendar}
                            className= "w-full mt-2 group rounded-md inline-flex items-center text-base font-medium border-2 border-grey1 cursor-pointer" >
                            <CalendarIcon
                                className={"ml-2 mr-3 h-5 w-5 group-hover:text-gray-500"}
                                aria-hidden="true"
                            />
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update: any) => {
                                    handleSetDateRange(update);
                                }}
                                isClearable
                                placeholderText="Choose Date"
                            />
                        </div>
                    </div>
                </>

            )}
        </>
    )
}

export default DatetimeButton;