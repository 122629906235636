
import { useLocation } from "react-router-dom";


const DepartmentButton = (props: any) => {

    const { departments, onChangeDepartment } = props;
    const { state: stateRouter } = useLocation<any>();
    const location = useLocation();
    const department = new URLSearchParams(location.search).get('department') || ""

    return (
        <>
            {
                !!stateRouter?.isShowBtnDepartment && departments.length > 0 && (
                    <div style={{ color: "#374151" }} className='text-sm mt-4'>
                        <div>Department</div>
                        <div style={{ marginBottom: "0.5rem" }} className="cursor-pointer mr-1 mt-2 border-2 border-grey1 p-1 rounded-md">
                            <select
                                id="location"
                                onChange={onChangeDepartment}
                                name="location"
                                style={{color: `${department == "" ? "#9CA3AF" : ""}`}}

                                className="cursor-pointer w-full block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                value={department}
                            >
                                {departments.map((item: any, index: number) => {
                                    return (
                                        <option
                                            key={index}
                                            value={item.value}
                                        >
                                            {item.item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>

                )
            }
        </>
    )
}

export default DepartmentButton;