import ReactMarkdown from 'react-markdown'

type RecommendationItemProps = {
    item: APP.ListData
    className: string
}


const RecommendationItem = (props: RecommendationItemProps) => {
    const { item } = props
    const renderData = item.data?.map((item: string, index: number) => {
        if (index % 2 === 0) {
            return <div key={'text-list-item-' + index} className={"px-5 py-4  text-xl text-gray font-normal bg-blueLight" + (index === props.item.data.length - 1 ? " rounded-b-lg" : "")}>
                <ReactMarkdown
                className='r-mkdwn'
                >{item}</ReactMarkdown>

            </div>
        } else {
            return <div key={'text-list-item-' + index} className={"px-5 py-4  text-xl text-gray font-normal" + (index === props.item.data.length - 1 ? " rounded-b-lg" : "")}>
                <ReactMarkdown
                 className='r-mkdwn'
                >{item}</ReactMarkdown>
            </div>
        }
    })
    return (
        <div className={props.className}>
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-bold text-primaryHeavy">{item.title}</h3>
            </div>
            {renderData}
        </div>
    )
}
export default RecommendationItem

