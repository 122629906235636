import moment from "moment";

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getToken = () => localStorage.getItem("token") || "";

export const formatDate = (date: string) => date ? moment(date).format("DD/MM/YYYY") : ""


export const add_params_and_push = (params: any, location: any, queryString: any, history: any) => {
    const query = queryString.parse(location.search)
    console.log('params', params)
    const search = queryString.stringify(Object.assign(query, params))
    console.log('location', location)
    history.push({
        pathname: location.pathname,
        search: search,
        state: location.state
    });
}

export const ten_years_ago = moment().subtract(10 * 12, 'months').format("YYYY-MM-DD");

export const today = moment().format("YYYY-MM-DD");
