

import React, { useState } from "react";
import BoxTitle from "components/BoxTitle";
import PackItemView from "containers/AppUsage/LearningPacks/Components/PackItemView";


const LearningPacksPdf = (props: any) => {
    const top_learn = props.top_learn;
    
    const leftView = top_learn?.previous_month?.map((item: any, index: number) => {
        return <PackItemView index={index} key={'pack-left-' + index} item={item} />
    })
    const rightView = top_learn?.current_month?.map((item: any, index: number) => {
        return <PackItemView index={index} key={'pack-right-' + index} item={item} />
    })
    return (
        <div className={props.className}>
            <div className="">
                <BoxTitle title={props.title} />
                <div className="flex px-4 py-5 flex-row justify-between">
                    <div className="flex-1 " >
                        <span className="text-xl leading-6 font-normal text-blue">Previous Month</span>
                        <ul className="flex flex-col flex-1" >
                            {leftView}
                        </ul>
                    </div>
                    <div className="flex-1 " >
                        <span className="text-xl leading-6 font-normal text-blue">Current Month</span>
                        <ul className="flex flex-col flex-1" >
                            {rightView}
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default LearningPacksPdf;
