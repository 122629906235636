

import React, { useState } from "react";
import PackItemView from './Components/PackItemView'
import { useSelector, RootStateOrAny } from 'react-redux'
import BoxTitle from "components/BoxTitle";
import { selectAuth } from "containers/SingIn/authSlice";


const LearningPacks = (props: any) => {
    const { top_learn } = useSelector(selectAuth)

    const leftView = top_learn?.previous_month.map((item: any, index: number) => {
        return <PackItemView index={index} key={'pack-left-' + index} item={item} />
    })
    const rightView = top_learn?.current_month.map((item: any, index: number) => {
        return <PackItemView index={index} key={'pack-right-' + index} item={item} />
    })

    return (
        <div className={props.className}>
            <div className="">
                <BoxTitle title={props.title} />
                <div className="flex px-4 py-5 flex-row justify-between">
                    <div className="flex-1 " >
                        <span className="text-xl leading-6 font-normal text-blue">Previous Month</span>
                        <ul className="flex flex-col flex-1" >
                            {leftView}
                        </ul>
                    </div>
                    <div className="flex-1 " >
                        <span className="text-xl leading-6 font-normal text-blue">Current Month</span>
                        <ul className="flex flex-col flex-1" >
                            {rightView}
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default LearningPacks
