import DassScoreChart from "./dass_scrore_chart"

const DepartmentHeath = (props: any) => {
    const {
        depression = [], 
        anxiety = [],
        stress = []
    } = props.departmental_health_overview || {};
    return ( 
        <div className="grid grid-cols-3 w-full">
            <div className="w-10/12">
                <div style={{color: "#004876"}} className="font-bold">Depression</div>
                {depression?.length > 0 ? depression.map((data: any) => {
                    return (
                        <DassScoreChart data={data} />
                    )
                }) : (
                    <div className="text-2xl">-</div>
                    )}
            </div>

            <div className="w-10/12">
            <div style={{color: "#004876"}} className="font-bold">Anxiety</div>
            {anxiety?.length>0 ? anxiety.map((data: any) => {
                    return (
                        <DassScoreChart data={data} />
                    )
                }): (
                    <div className="text-2xl">-</div>
                    )}
            </div>
            <div className="w-10/12">
            <div style={{color: "#004876"}} className="font-bold">Stress</div>
            {stress?.length>0?stress.map((data: any) => {
                    return (
                        <DassScoreChart data={data} />
                    )
                }):(
                    <div className="text-2xl">-</div>
                    )}
            </div>
        </div >
    )
}

export default DepartmentHeath

