import {
    DownloadIcon
} from '@heroicons/react/outline';
const ExportPdfButton = (props: any) => {
    return (
        <div className=" sm:px-6 md:px-8 mt-4">

            <button
                onClick={props.exportPdf}
                style={{ boxShadow: "0px 3px 8px -1px #3232470D" }}
                className="text-base p-3.5 bg-white rounded-md">
                <div className="flex">
                    <div>
                        <DownloadIcon color="#9CA3AF" width="25" height="25" />

                    </div>
                    <div className="ml-2" style={{ color: "#48566A" }}>
                        Download PDF
                    </div>
                </div>

            </button>
        </div>
    )
}

export default ExportPdfButton;
