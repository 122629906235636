import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import BoxTitle from "components/BoxTitle";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type ImpactRatingProps = {
  className: string;
  title: string;
  webinar?: APP.Webinar;
};
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      // position: 'top' as const,
    },
    title: {
      display: true,
      text: "",
    },
    datalabels: { display: true },

  },
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 5
    }
  }
};
const ImpactRating = (props: ImpactRatingProps) => {
  const { webinar, title } = props;
  const labels = [webinar?.label_before, webinar?.label_after];
  const legends = [
    {
      text: "Understanding of topic Before",
      background: "rgba(77, 181, 187, 0.5)",
    },
    {
      text: "Understanding of topic After",
      background: "rgba(227, 188, 74, 0.5)",
    },
  ];
  const data = {
    labels,
    datasets: [
      {
        data: [webinar?.before, webinar?.after],
        backgroundColor: ["rgba(77, 181, 187, 0.5)", "rgba(227, 188, 74, 0.5)"],
      },
    ],
  };
  return (
    <div className={props.className}>
      <div className="">
        <BoxTitle title={title} />
        <div className=" justify-around py-5 px-5">
          <div className="flex justify-center">
            {legends.map((legend) => {
              return (
                <div className="ml-6 -mb-3 flex justify-center items-center">
                  <div
                    style={{ background: legend.background }}
                    className="w-10 h-3"
                  ></div>
                  <span className="ml-2 text text-xs">{legend.text}</span>
                </div>
              );
            })}
          </div>
          <div className="chart">
            <Bar height={100} options={options} data={data} />
          </div>
        </div>
      </div>
      <div className="flex mx-auto items-center justify-center py-3">
        <span className="font-normal text-base text-gray500" >{'Data above represents the average of participants’ understanding of the topic on a scale of 1 (lowest) to 5 (highest) before and after the webinar.'}</span>
      </div>
    </div>
  );
};

export default ImpactRating;
