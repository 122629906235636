
import {
  REACT_APP_METABASE_USER_ACQUISITION
} from 'constants/megabase'
import ChartPdf from '.'


const UserAcqisitionChartPdf = () => {
  return (
    <main>
      <div className="bg-grey px-4">
        <div className="mx-auto">
          <h1 className="text-2xl font-bold text-blue">User Demographics</h1>
        </div>
        <div className="">
          <div className="pt-2">
            <div className="justify-between">
              <ChartPdf height={"85vh"} className={'bg-white shadow sm:rounded-lg flex-1'} question={REACT_APP_METABASE_USER_ACQUISITION} />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default UserAcqisitionChartPdf