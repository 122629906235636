
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { formatDate } from "utils";

import IconTooltip from "components/IconTooltip";
type ListProgramsTableProps = {
    data: any
}

const ListProgramsTable = (props: ListProgramsTableProps) => {
    const history = useHistory();
    const handleActiPrItem = (id: string | number) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        history.push(`/activation-program/${id}`)
    }
    const buttonRef = useRef();

    return (

        <div className="w-full mx-auto">

            <div className="flex flex-wrap justify-between space-x-8  ">
                <div className="w-full flex flex-col">
                    <div className="w-full -my-2 overflow-x-auto">
                        <div className="w-full py-2 align-middle inline-block ">
                            <div className="w-full shadow">
                                <table
                                    style={{ minWidth: "1550px" }}
                                    className=" divide-y divide-grey1">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                style={{ width: "10%" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Date
                                            </th>
                                            <th
                                                style={{ width: "30%" }}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Title of program
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Target participants
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                No. of participants
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                AVE. webinar rating
                                            </th>
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >

                                                <div >Ave. Engagement Rate</div>
                                                <IconTooltip
                                                    dataFor={"aveEng"}
                                                    style={{ top: "20px", right: "5px", position: "absolute" }}
                                                    title={"User engagement during webinar through survey and chat participation"}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >

                                                <div > Ave. Extended Q&A retention rate</div>
                                                <IconTooltip
                                                    dataFor={"aveExten"}
                                                    style={{ top: "20px", right: "5px", position: "absolute" }}
                                                    title={"Participants who stayed back for the Q&A at the end of the session"}
                                                />

                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props?.data?.map((item: any, personIdx: number) => (
                                            <tr
                                                onClick={() => handleActiPrItem(item.id)}
                                                key={item.id}
                                                style={{ cursor: "pointer" }}
                                                className={`${personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'} hover:bg-[#e6fcf9]`}>

                                                <td className="px-6 py-4   text-sm font-medium text-gray-900">
                                                    {formatDate(item.begin_at)}</td>
                                                <td className="px-6 py-4   text-sm text-gray-500 ">{item.title}</td>
                                                <td className="px-6 py-4   text-sm text-gray-500">{item.target}</td>
                                                <td className="px-6 py-4   text-sm text-gray-500 text-center">{item.number_participant}</td>
                                                <td className="px-6 py-4   text-sm text-gray-500 text-center">
                                                    <span className="w-8 inline-block text-center" >
                                                        {item.average_webinar_ratings}
                                                    </span>
                                                    <span className="text-sm">
                                                        out of 5
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4  text-sm text-gray-500  text-center">
                                                    {item.average_engagement_rate}
                                                </td>
                                                <td className="px-6 py-4   text-sm text-gray-500 text-center">{item.extended_qa_retention_rate}</td>

                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default ListProgramsTable

