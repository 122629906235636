import SectionLabel from 'components/Section'
import { selectAuth } from 'containers/SingIn/authSlice'
import { useSelector } from 'react-redux'

const ManageEmployees = () => {
    const { org_department_users } = useSelector(selectAuth)

    return (
        <div className="py-6 bg-grey">
            
            <div className="mx-auto px-4 sm:px-6 md:px-8 mb-10">
            <SectionLabel title="Manage Employees " />
                    <div className="w-full mx-auto mt-6">
                        <div className="flex flex-wrap justify-between space-x-8  ">
                            <div className="w-full flex flex-col">
                                <div className="w-full -my-2 overflow-x-auto">
                                    <div className="w-full py-2 align-middle inline-block ">
                                        <div className="w-full shadow">
                                            <table
                                                className=" divide-y divide-grey1 w-full">
                                                <thead className="bg-white">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            First Name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Last Name
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Email
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Department
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {org_department_users?.map((item: any, personIdx: number) => (
                                                        <tr
                                                            key={item.id}
                                                            className={`${personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'}`}>
                                                            <td className="px-6 py-4   text-sm font-medium text-gray-900">{item.first_name}</td>
                                                            <td className="px-6 py-4   text-sm text-gray-500">{item.last_name}</td>
                                                            <td className="px-6 py-4   text-sm text-gray-500">{item.email}</td>
                                                            <td className="px-6 py-4   text-sm text-gray-500">{item.department}</td>
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
             </div>
         </div >

    )
}

export default ManageEmployees;