
import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";


const MonthQuarter = (props: any) => {

    const { departments, onChangeMonthQuarter } = props;
    const { state: stateRouter } = useLocation<any>();
    const location = useLocation();
    const month_quarter = new URLSearchParams(location.search).get('month_quarter') || "Entire year"
    console.log('month_quarter', month_quarter)
    const options = [
        {
            item: "Jan",
            value: "Jan"
        },
        {
            item: "Feb",
            value: "Feb"
        },
        {
            item: "Mar",
            value: "Mar"
        },
        {
            item: "Apr",
            value: "Apr"
        },
        {
            item: "May",
            value: "May"
        },
        {
            item: "Jun",
            value: "Jun"
        },
        {
            item: "Jul",
            value: "Jul"
        },
        {
            item: "Aug",
            value: "Aug"
        },
    
        {
            item: "Sep",
            value: "Sep"
        },
        {
            item: "Oct",
            value: "Oct"
        },
        {
            item: "Nov",
            value: "Nov"
        },
        {
            item: "Dec",
            value: "Dec"
        },
        {
            item: "Q1",
            value: "Q1"
        },
        {
            item: "Q2",
            value: "Q2"
        },
        {
            item: "Q3",
            value: "Q3"
        },
        {
            item: "Q4",
            value: "Q4"
        },
        {
            item: "Entire year",
            value: "Entire year"
        }
    ]
    return (
        <>
           
                    <div 
                    style={{marginBottom: "0.5rem"}}
                    className="mr-1 border-2 border-grey1 p-1 rounded-md">
                        <select
                            style={{minWidth: "56px"}}
                            id="location"
                            onChange={onChangeMonthQuarter}
                            name="location"
                            className=" block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            value={month_quarter}
                        >
                            {options.map((item: any, index: number) => {
                                return (
                                    <option
                                        key={index}
                                        value={item.value}
                                    >
                                        {item.item}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
        </>
    )
}

export default MonthQuarter;