import { useSelector } from 'react-redux';
import { selectAuth } from "containers/SingIn/authSlice";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';

import { getMetabaseData } from 'containers/SingIn/authAPI';
import { REACT_APP_METABASE_AIA_TOP_USER_OUTCOMES } from 'constants/megabase';

const AiaTopUserOutcomesPdf = (props: any) => {
    const location = useLocation();
  
    const decode_and_parse: any = (key: string, defaultValue: string) => {
        const value = new URLSearchParams(location.search).get(key) || defaultValue;
        return JSON.parse(decodeURIComponent(value))
    }

    const topUserOutcomes = decode_and_parse("topUserOutcomes", "[]");

    const formatNumber = (num: any) => {
        // Check if the number has any decimal places
        if (num && num % 1 !== 0) {
          // If it does, format it to 2 decimal places
          return parseFloat(num.toFixed(1));
        }
        // If it's an integer, return it as is
        return num;
      }
    return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)
        <main id="myiFrame">
          <div className="py-6 px-8">
        <div style={{color: "#004876"}} className="text-lg">Top User Outcomes</div>
        <div className="flex flex-wrap items-center justify-start">
            {
                topUserOutcomes?.map((data:any, index:number) => (
                    <div key={index} className="w-6/12 mt-4 flex items-center justify-start">
                    <div style={{color: "#009383"}} className="text-2xl w-1/5 font-bold	">
                    {Math.round(data?.value)}%
                    </div>
                    <div style={{color: "#4B5563"}} className="text-sm ml-2 w-4/5">
                    {data?.text}
                    </div>
                </div>
                ))
            }
        </div>
      
    </div>
        </main>
        
    )
}

export default AiaTopUserOutcomesPdf;
