import RatingBarOnly from "components/RatingBarOnly";
import images from "assets/images";
interface PackItemViewProps {
    item: APP.PackItem;
    index: number;
}

const PackItemView = (props: PackItemViewProps) => {
    const { item, index } = props;
    return (
        <li className="flex flex-row py-0 my-5 items-start">
            <div className="px-2">
                <img
                    className="learn-icon"
                    src={
                        props.index === 0
                            ? images.st1
                            : props.index === 1
                                ? images.st2
                                : images.st3
                    }
                    alt="Workflow"
                />
            </div>
            <div className="flex flex-col justify-between space-y-2">
                <h5 className="font-bold text-2xl text-blue">{item.pack_title}</h5>
                <div className="flex flex-row items-center">
                    <span className="font-normal text-xl text-gray">Average rating</span>
                    <RatingBarOnly rating={item.avg_rating} starWidth={"x"} />
                </div>
                {index === 0 && (
                    <h3 className="font-semibold text-xl text-gray">Most Popular Pack</h3>
                )}
            </div>
        </li>
    );
};

export default PackItemView;
