import SectionCenterLabel from "components/SectionCenter"

type NumberType = {
  name: string,
  depression: string,
  anxiety: string,
  stress: string
}

const people: NumberType[] = [
  { name: 'Normal', depression: '0-9', anxiety: '0-7', stress: '0-14' },
  { name: 'Mild', depression: '10-13', anxiety: '8-9', stress: '15-18' },
  { name: 'Moderate', depression: '14-20', anxiety: '10-14', stress: '19-25' },
  { name: 'Severe', depression: '21-27', anxiety: '15-19', stress: '26-33' },
  { name: 'Extremely Severe', depression: '28', anxiety: '20', stress: '34' },
  // More people...
]


const ClinicalInsightsPdf = () => {

  return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)

    <div id="myiFrame" className="py-4">
    <div className="CardVisualization flex flex-col mx-auto px-4 sm:px-6 md:px-8">
      <SectionCenterLabel title={"Interpretation of DASS Scores"} />
    </div>

    <div className="flex flex-wrap justify-between space-x-8 mx-8 bg-white shadow sm:rounded-lg flex-1 ">
      <div className="w-full mx-auto">
        <div className="flex flex-wrap justify-between space-x-8  ">
          <div className="w-full flex flex-col">
            <div className="w-full -my-2 overflow-x-auto">
              <div className="w-full py-2 align-middle inline-block ">
                <div className="w-full shadow overflow-hidden">
                  <table className="w-full divide-y divide-grey1">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Meaning
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Depression
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Anxiety
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Stress
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {people.map((person: NumberType, personIdx) => (
                        <tr key={person.name} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.depression}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.anxiety}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{person.stress}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ClinicalInsightsPdf